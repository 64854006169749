import { EarlyWarningService } from './../early-warning/early-warning.service';
import { BaseClass } from '../base/base.service';
import { ReportsType } from '@/pages/Reports/models/reports.model';
import { StatisticsService } from '../statistics/statistics.service';
import { StatistcsPeriod } from '@/pages/Reports/models/statistics.model';

export class ReportsProvider extends BaseClass {
  constructor() {
    super('reports/');
  }

  async requestSearch(
    reportType: ReportsType,
    filters?: string,
    period?: string
  ) {
    switch (reportType) {
      case ReportsType.PULLOUT: {
        const pullouts = await this.getSchedulesList('pullouts', filters);
        return pullouts;
      }
      case ReportsType.DELIVERY: {
        const pullouts = await this.getSchedulesList('deliveries', filters);
        return pullouts;
      }
      case ReportsType.RETURN: {
        const pullouts = await this.getSchedulesList('returns', filters);
        return pullouts;
      }
      case ReportsType.DEMURRAGE: {
        // limit: -1 means get ALL
        const demurrage = await this.getReportsList('demurrage', filters);
        return demurrage;
      }
      case ReportsType.PERDIEM: {
        // limit: -1 means get ALL
        const perdiem = await this.getReportsList('perdiem', filters);
        return perdiem;
      }
      case ReportsType.STATISTICS: {
        const statistics = await this.getSchedulesStatistics(period, filters);
        return statistics;
      }
      case ReportsType['EARLY-WARNING']: {
        return [];
      }
    }
  }

  async getSchedulesList(type: string, filter: string) {
    const res = await this.get(`schedules/${type}`, { filter });
    return res.data.data;
  }

  async getReportsList(type: string, filter: string) {
    // limit: -1 means get ALL
    const res = await this.get(type, { filter, limit: -1 });
    return res.data;
  }

  async getSchedulesStatistics(statisticsPeriod: string, filter: string) {
    const res = await StatisticsService.getStatistic(statisticsPeriod, filter);

    return res;
  }

  async getSchedulesOrdersList(type: string, filter: string) {
    const res = await this.get(`schedules/${type}/orders`, { filter });
    return res.data.data;
  }

  async getOrders(reportType: ReportsType, filters?: string) {
    switch (reportType) {
      case ReportsType.PULLOUT: {
        const pullouts = await this.getSchedulesOrdersList('pullouts', filters);
        return pullouts;
      }
      case ReportsType.DELIVERY: {
        const pullouts = await this.getSchedulesOrdersList(
          'deliveries',
          filters
        );
        return pullouts;
      }
      case ReportsType.RETURN: {
        const pullouts = await this.getSchedulesOrdersList('returns', filters);
        return pullouts;
      }
    }
  }

  async getExcel(chunk: string, filter: string) {
    const res = await this.get(`${chunk}/excel`, { filter });
    return res.data;
  }

  async getReportsExcelData(
    reportType: ReportsType,
    filters?: string,
    statisticsPeriod?: string
  ) {
    switch (reportType) {
      case ReportsType.PULLOUT: {
        const pullouts = await this.getExcel(
          'schedules/pullouts/orders',
          filters
        );
        return pullouts;
      }
      case ReportsType.DELIVERY: {
        const pullouts = await this.getExcel(
          'schedules/deliveries/orders',
          filters
        );
        return pullouts;
      }
      case ReportsType.RETURN: {
        const pullouts = await this.getExcel(
          'schedules/returns/orders',
          filters
        );
        return pullouts;
      }
      case ReportsType.DEMURRAGE: {
        const pullouts = await this.getExcel('demurrage', filters);
        return pullouts;
      }
      case ReportsType.PERDIEM: {
        const pullouts = await this.getExcel('perdiem', filters);
        return pullouts;
      }
      case ReportsType.STATISTICS: {
        const statistics = await StatisticsService.getExcel(
          StatistcsPeriod[statisticsPeriod],
          filters
        );
        return statistics;
      }
      case ReportsType['EARLY-WARNING']: {
        const statistics = await EarlyWarningService.getExcel(filters);
        return statistics;
      }
    }
  }
}

export const ReportsService = new ReportsProvider();
