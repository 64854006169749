var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"date-time-picker"},[_c('tms-date-picker',{ref:"tmsDateTimePicker",attrs:{"size":"sm","min":_vm.min,"isDirty":_vm.isDirty,"relatedControlName":_vm.relatedControlName,"currentControlName":_vm.currentControlName,"isCorrectFortmat":_vm.isCorrectFortmat},on:{"input":_vm.datePickerChange},model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}}),(!_vm.onlyDate)?_c('TimePicker',{on:{"input":_vm.onTimeChange},model:{value:(_vm.timeModel),callback:function ($$v) {_vm.timeModel=$$v},expression:"timeModel"}}):_vm._e()],1),_c('div',[(_vm.requiredControlsValidation)?_c('b-form-invalid-feedback',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-block",attrs:{"title":_vm.$t('validations.invalidReturn')}}):_vm._e(),(_vm.errorMessage)?_c('b-form-invalid-feedback',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-block",attrs:{"title":_vm.errorMessage}}):_vm._e(),(
        _vm.isRelatedValidation() &&
          !_vm.errorMessage &&
          ((_vm.isDifferentTime() && _vm.isDirty) ||
            (isNaN(this.inputDiffMinutes) && _vm.isDirty))
      )?_c('b-form-invalid-feedback',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-block",attrs:{"id":"input-picker","title":_vm.$t('validations.laterThenMsg', {
          current: _vm.currentControlName,
          related: _vm.relatedControlName
        })}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }