import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { MasterDataService } from '../../../shared/services/mater-data/master-data.service';
import { ToastHelper } from '../../../utils/toast.util';
import { Employee } from './employee.model';

@Module({
  namespaced: true,
  name: 'employee'
})
export default class EmployeeModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 1;
  empType: string;
  meta: any = null;

  employees: Employee[] | any = [];

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setEmployees({ data, meta }) {
    this.employees = data;
    this.meta = meta;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setEmpType(empType: string) {
    this.empType = empType;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await MasterDataService.getEmployees();
      this.context.commit('setEmployees', res);
      console.log(res.meta);
    } catch (err) {
      console.log('employeesImport Error', err);
      ToastHelper.show('Employee', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await MasterDataService.getEmployees(this.filters);
      console.log(res.meta);
      this.context.commit('setEmployees', res);
    } catch (err) {
      console.log('employeesImport Error', err);
      ToastHelper.show('Employee', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async createUser() {}
}
