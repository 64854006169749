export const formatCurrency = (value: string | number) => {
  if (typeof value !== 'string') {
    return value;
  }

  return parseFloat(value.replace(/[^0-9.]/g, ''));
};

export const CurrencyFilter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
