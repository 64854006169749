import { BaseClass } from './base/base.service';

export class FileUploadProvider extends BaseClass {
  constructor() {
    super('files');
  }

  async getUrl(fileName: string) {
    const res = await this.get(`put-pre-signed-url?fileName=${fileName}`);
    return res.data;
  }

  async uploadFile(url: string, file) {
    console.log(url)
    console.log(file)
    return this.put(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  }
}

export const FileUploadService = new FileUploadProvider();
