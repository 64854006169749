export enum YmsType {
  DAILY = 'DAILY',
  INVENTORY = 'INVENTORY',
  DAMAGE = 'DAMAGE',
  NAVTRAC = 'NAVTRAC'
}

export const YmsLinks = {
  [YmsType.DAILY]: 'daily',
  [YmsType.INVENTORY]: 'inventory',
  [YmsType.DAMAGE]: 'damage',
  [YmsType.NAVTRAC]: 'nav-trac'
};
