export const StatisticsTypes = {
  deliveries: {
    title: 'DO',
    category: ['All'],
    subcategory: []
  },
  pullouts: {
    title: 'PULL OUT',
    category: ['All'],
    subcategory: []
  },
  orders: {
    title: 'DILV & DROP',
    category: ['All'],
    subcategory: []
  },
  pickups: {
    title: 'PICK UP',
    category: ['All'],
    subcategory: []
  },
  returns: {
    title: 'RETURN',
    category: ['All'],
    subcategory: []
  },
  invoices: {
    title: 'INVOICE($)',
    category: ['All'],
    subcategory: []
  },
  pierpass: {
    title: 'PIERPASS($)',
    category: ['All'],
    subcategory: []
  },
  demurrage: {
    title: 'DEMURRAGE($)',
    category: ['All'],
    subcategory: []
  },
  perdiem: {
    title: 'PERDIEM($)',
    category: ['All'],
    subcategory: []
  }
};

export enum StatistcsPeriod {
  weekly = 'days',
  monthly = 'weeks',
  yearly = 'months'
}
