





















































import { DATE_TIME_FORMAT } from '@/utils/date.util';
import { BIconPlus } from 'bootstrap-vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
    BIconPlus
  }
})
export default class ScheduleTableView extends Vue {
  @Prop() schedule;

  DATE_TIME_FORMAT = DATE_TIME_FORMAT;
}
