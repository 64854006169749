import { WoAction } from './../components/wo/models';
import { BaseClass } from './base/base.service';

class File extends BaseClass {
  constructor() {
    super('orders');
  }

  async getFiles(type: string, id: string) {
    const res = await this.get(`${WoAction[type]}/${id}/files`);
    return res.data;
  }

  async createFile(type: string, id: string, data) {
    const res = await this.post(`${WoAction[type]}/${id}/files`, data);
    return res.data;
  }

  async getFileLink(type: string, id: string, fileId: string) {
    const res = await this.get(`${WoAction[type]}/${id}/files/${fileId}`);
    return res.data;
  }

  async updateFile(type: string, id: string, fileId: string, data) {
    const res = await this.put(`${WoAction[type]}/${id}/files/${fileId}`, data);
    return res.data;
  }

  async deleteFile(type: string, id: string, fileId: string, data) {
    const res = await this.delete(`${WoAction[type]}/${id}/files/${fileId}`);
    return res.data;
  }
}

export const FileService = new File();
