import { Route } from 'vue-router';

/**
 * 
 * @param routeName
 */
export function generateRouterClasses(route: Route): string[] {
  switch (route.name) {
    case 'import':
      return generateForDispatchImport();
    case 'export-booking':
    case 'export-unavailable':
    case 'export-available':
    case 'export-delivery':
    case 'export-return':
      return generateForDispatchExport();
    case 'van':
      return generateForDispatchVan();
    case 'main':
      return generateForMainClasses(route);
    case 'workOrder':
      return generateForWprkOrderClasses(route);
    default:
      return [];
  }
}

function generateForDispatchImport(): string[] {
  return ['tms-dispatch-import-route', 'tms-import-colors', 'dispatch'];
}

function generateForDispatchExport(): string[] {
  return ['tms-dispatch-export-route', 'tms-export-colors', 'dispatch'];
}

function generateForDispatchVan(): string[] {
  return ['tms-dispatch-van-route', 'tms-van-colors', 'dispatch'];
}

function generateForMainClasses(route: Route): string[] {
  switch (route.params.type) {
    case 'IMP-REG':
      return ['tms-main-import-route', 'tms-import-colors'];
    case 'EXP-REG':
      return ['tms-main-export-route', 'tms-export-colors'];
    case 'VAN':
      return ['tms-main-van-route', 'tms-van-colors'];
    default:
      return [];
  }
}

function generateForWprkOrderClasses(route: Route): string[] {
  switch (route.params.type) {
    case 'IMP-REG':
    case 'IMP-SHUTTLE':
      return ['tms-work-order-import-route', 'tms-import-colors'];
    case 'EXP-REG':
    case 'EXP-SHUTTLE':
      return ['tms-work-order-export-route', 'tms-export-colors'];
    case 'VAN':
      return ['tms-work-order-van-route', 'tms-van-colors'];
    default:
      return [];
  }
}
