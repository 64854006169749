import { FilterConditions } from './../filter/filter.service';
import { ToastHelper } from '@/utils/toast.util';
import { BaseClass } from '../base/base.service';
import { DispatchType } from '@/pages/Dispatch/models/dispatch.model';

export class EarlyWarningProvider extends BaseClass {
  constructor() {
    super('early-warnings');
  }

  search(filter: string) {
    // limit: -1 means get ALL
    return this.get(``, { filter, limit: -1 });
  }

  getConfig(division: string) {
    // limit: -1 means get ALL
    return this.get(`/configs`, { division, limit: -1 });
  }

  getActiveWarnings(division: string) {
    return this.get(`/active`, { division });
  }

  async getExcel(filter: string) {
    const res = await this.get(`/excel`, { filter });
    return res.data;
  }

  async close(id: string) {
    try {
      await this.patch(`/${id}`, {});
      ToastHelper.show(
        'Early warning',
        'Early warning closed',
        5000,
        'success'
      );
    } catch (e) {
      ToastHelper.show('Early warning', e.message, 5000, 'danger');
    }
  }

  async updateConfig(id: string, data) {
    try {
      await this.put(`/configs/${id}`, data);
      ToastHelper.show(
        'Config updated successfully',
        'Config updated successfully',
        5000,
        'success'
      );
    } catch (e) {
      ToastHelper.show('Config update failed', e.message, 5000, 'danger');
    }
  }
}

export const EarlyWarningService = new EarlyWarningProvider();
