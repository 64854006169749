import { BaseClass } from '../base/base.service';
import { ToastHelper } from './../../../utils/toast.util';
import { WoAction } from './../../components/wo/models';

class ApArServiceProvider extends BaseClass {
  constructor() {
    super('orders');
  }

  async deleteAP(type: string, orderNumber: string, id: string): Promise<any> {
    return this.delete(
      `${WoAction[type]}/${orderNumber}/account-payables/${id}`
    )
      .then(() => {
        ToastHelper.show(
          'Account Payable',
          `Account Payable is deleted.`,
          5000,
          'success'
        );
      })
      .catch(res => {
        ToastHelper.show(
          'Account Payable',
          res.response.data.error,
          5000,
          'danger'
        );
        return res;
      });
  }

  async deleteAR(type: string, orderNumber: string, id: string): Promise<any> {
    return this.delete(
      `${WoAction[type]}/${orderNumber}/account-receivables/${id}`
    )
      .then(() => {
        ToastHelper.show(
          'Account Receivable',
          `Account Receivable is deleted.`,
          5000,
          'success'
        );
      })
      .catch(res => {
        ToastHelper.show(
          'Account Receivable',
          res.response.data.error,
          5000,
          'danger'
        );
        return res;
      });
  }
}

export const ApArService = new ApArServiceProvider();
