export enum ConfigType {
  DIVISION = 'divisions',
  POOLS = 'pools',
  SIZE = 'container-sizes',
  SSL = 'ssl',
  TYPE = 'container-types'
}

export const StatusOptions = [
  { value: null, text: 'ALL' },
  { value: true, text: 'ACTIVE' },
  { value: false, text: 'INACTIVE' }
];
