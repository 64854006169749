import { TableHeaders } from '@/shared/components/table/models';
import { boolToYN } from '@/utils/boolToYN';

export const ExportRegularTableHeaders: TableHeaders[] = [
  { label: 'DATE', key: 'date' },
  { label: 'WO #', key: 'orderNumber' },
  { label: 'BILL TO', key: 'billTo' },
  {
    key: 'bookingNumber',
    label: 'Booking'
  },
  {
    key: 'houseBillOfLading',
    label: 'House BL'
  },
  {
    key: 'referenceNumber',
    label: 'REF'
  },
  {
    key: 'poNumber',
    label: 'PO'
  },
  {
    key: 'deliveryNumber',
    label: 'DELIVERY #'
  },
  {
    key: 'containerNumber',
    label: 'Cont #'
  },
  {
    key: 'containerSize',
    label: 'SZ'
  },
  {
    key: 'containerType',
    label: 'Type'
  },
  {
    key: 'shippingLine',
    label: 'SSL'
  },
  {
    key: 'vessel',
    label: 'Vessel'
  },
  {
    key: 'chassisNumber',
    label: 'CHASSIS #'
  },
  {
    key: 'chassisPool',
    label: 'POOL'
  },
  {
    key: 'wms',
    label: 'Wms',
    formatter: boolToYN
  },
  {
    key: 'bondEntry',
    label: 'Bond'
  },
  {
    key: 'erd',
    label: 'ERD'
  },
  {
    key: 'cutOff',
    label: 'C/O'
  },
  { label: 'SEAL #', key: 'seal' },
  { label: 'Commodity', key: 'commodity' },
  {
    key: 'weight',
    label: 'WGT'
  },
  {
    key: 'quantity',
    label: 'QTY'
  },
  {
    key: 'unit',
    label: 'Unit'
  },
  {
    key: 'freeDays',
    label: 'Free Days'
  },
  {
    key: 'freeDaysType',
    label: 'FD Type'
  },
  {
    key: 'dropLive',
    label: 'Drop/Live'
  },
  {
    key: 'overHeight',
    label: 'OH',
    formatter: boolToYN
  },
  {
    key: 'overWeight',
    label: 'OW',
    formatter: boolToYN
  },
  {
    key: 'haz',
    label: 'HAZ',
    formatter: boolToYN
  },
  {
    key: 'hot',
    label: 'HOT',
    formatter: boolToYN
  },
  {
    key: 'pulloutLocation',
    label: 'P/OUT'
  },
  {
    key: 'pulloutSchedule',
    label: 'S IN'
  },
  { label: 'DILV', key: 'deliveryLocation' },
  {
    key: 'deliverySchedule',
    label: 'S IN'
  },
  {
    key: 'returnLocation',
    label: 'RTN'
  },
  {
    key: 'returnSchedule',
    label: 'S IN'
  }
];
