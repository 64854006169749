import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { DriverService } from '@/shared/services/driver/driver.service';
import { ToastHelper } from '@/utils/toast.util';

import { DriverTypes } from '@/pages/Driver/models/driver.model';
import { ExcelData } from './driver.model';

@Module({
  namespaced: true,
  name: 'driver'
})
export default class DriverModule extends VuexModule {
  loading = false;
  driverData = [];
  driverType: any;
  excelData: ExcelData | any = {};
  filters = null;
  meta: any = null;
  page = 1;

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_FILTERS(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public SET_DRIVER_DATA(payload) {
    this.driverData = payload ? [...payload] : [];
  }

  @Mutation
  public SET_DRIVER_TYPE(payload) {
    this.driverType = payload;
  }

  @Mutation
  public SET_EXCEL_DATA(payload) {
    this.excelData = payload ? { ...payload } : {};
  }

  @Mutation
  public SET_META(payload) {
    this.meta = { ...payload };
  }

  @Action
  async init() {
    this.context.commit('SET_FILTERS', null);
    this.context.commit('SET_DRIVER_DATA', []);
  }

  @Action
  async search(payload: { driverType: DriverTypes }) {
    const { driverType } = { ...payload };

    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await DriverService.searchRequest(driverType, this.filters);

      if (!res.data?.data) {
        this.context.commit('SET_DRIVER_DATA', res.data);
      } else {
        this.context.commit('SET_DRIVER_DATA', res.data?.data);
        this.context.commit('SET_META', res.data?.meta);
      }
    } catch (err) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Driver Error', err);
      ToastHelper.show('Driver', err?.response?.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async excel(driverType: DriverTypes) {
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await DriverService.getExcel(driverType, this.filters);
      this.context.commit('SET_EXCEL_DATA', res.data);
    } catch (error) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Driver excel Error', error);
      ToastHelper.show('Driver excel', error.response.data?.error);
    }
    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async setFilters(filters) {
    this.context.commit('SET_FILTERS', filters);
  }
}
