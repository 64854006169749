import { FileUploadService } from '@/shared/services/fileUpload.service';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { WoAction, WoType } from '../../../shared/components/wo/models';
import { WorkOrderService } from '../../../shared/services/wo/wo-import.service';

import {
  convertFilesToAPIFormat,
  convertStringsToInteger,
  removeEmptyFormProperties
} from './../../../utils/form.util';
import { ToastHelper } from './../../../utils/toast.util';
import { WorkOrder } from './work-order.model';

@Module({
  namespaced: true,
  name: 'workOrder'
})
export default class WorkOrderModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  workOrders: WorkOrder[] | any = [];
  fileUploadService = FileUploadService;

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setWorkOrders(wos: WorkOrder[] | any) {
    this.workOrders = wos;
  }

  @Mutation
  public addWorkOrder(wo: WorkOrder) {
    this.workOrders.push(wo);
  }

  @Action
  async createWO(data: any) {
    this.context.commit('setLoadingStatus', true);
    this.context.commit('setErrorMessage', null);
    try {
      data.data.files = [];

      const files = ['do', 'cf', 'other'];

      const fileUploads = [];

      files.forEach(file => {
        if (data.data[file]?.file) {
          fileUploads.push(
            this.fileUploadService.uploadFile(
              data.data[file].tempS3PreSignedUrl,
              data.data[file].file
            )
          );
        }
      });

      await Promise.all(fileUploads);
      convertFilesToAPIFormat(data.data, ['do', 'cf', 'other']);

      removeEmptyFormProperties(data.data);
      convertStringsToInteger(data.data, ['freeDays', 'weight', 'quantity']);

      WorkOrderService.post(WoAction[data.woType], data.data)
        .then(res => {
          const workOrder = WorkOrderService.convertWOToViewFormat(res.data);
          this.context.commit('addWorkOrder', workOrder);
          ToastHelper.show(
            data.woType,
            `${data.woType} ${data.successMsg}`,
            8000,
            'success'
          );
          this.context.commit('setLoadingStatus', false);
        })
        .catch(err => {
          console.log('workOrderImport Error', err);
          ToastHelper.show(
            data.woType,
            err.response.data.error,
            8000,
            'danger'
          );
          this.context.commit('setLoadingStatus', false);
        });

      return true;
    } catch (err) {
      console.log('workOrderImport Error', err);
      ToastHelper.show(data.woType, err.message, 8000, 'danger');
      this.context.commit('setLoadingStatus', false);
      return false;
    }
  }

  @Action({ commit: 'setWorkOrders' })
  async getWorkOrders(woType: WoType) {
    try {
      const workOrders = await WorkOrderService.get(WoAction[woType]);
      const wos = WorkOrderService.convertWOToViewFormat(workOrders.data);
      return wos;
    } catch (e) {
      console.log('getWorkOrders Error', e);
    }
  }

  @Action({})
  async updateWorkOrder(woType: WoType, data) {
    try {
      await WorkOrderService.put(WoAction[woType], data);
    } catch (e) {
      ToastHelper.show('Error during update', e.response.data?.error);
      console.log('Error during update', e);
    }
  }

  @Action({ commit: 'setWorkOrders' })
  async getTodaysWorkOrders(woType: WoType) {
    try {
      const workOrders = await WorkOrderService.get(
        `${WoAction[woType]}/today`
      );
      return WorkOrderService.convertWOToViewFormat(workOrders.data.data);
    } catch (e) {
      console.log('getWorkOrders Error', e);
    }
  }

  @Action
  async createUser() {}
}
