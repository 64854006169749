export class FieldChangeEventBus {
  private fieldChangeCallbacks = [];

  subscribeToFieldChange(callback: (event: FildChangeEvent) => any): Function {
    this.fieldChangeCallbacks.push(callback);

    return () => {
      const index = this.fieldChangeCallbacks.indexOf(callback);

      if (index !== -1) {
        this.fieldChangeCallbacks.splice(index, 1);
      }
    };
  }

  notifyChanges(event: FildChangeEvent) {
    this.onFieldChange(event);
  }

  private onFieldChange(event: FildChangeEvent) {
    this.fieldChangeCallbacks.forEach(callback => callback(event));
  }
}

export interface FildChangeEvent {
  fieldKey: string;
  newValue: unknown;
  contextData?: any;
  row?: number;
}
