import { ImportRegularTableHeaders } from './import-regular';
import { ExportRegularTableHeaders } from './export-regular';
import { VanRegularTableHeaders } from './van-regular';
import { CyToCyTableHeaders } from './cy-to-cy';
import { BareChassisTableHeaders } from './bare-chassis';

export enum woType {
  ImportShuttle = 'IMP-SHUTTLE',
  ImportRegular = 'IMP-REG',
  ExportRegular = 'EXP-REG',
  ExportShuttle = 'EXP-SHUTTLE',
  VanRegular = 'VAN',
  CyToCy = 'CY-TO-CY',
  BareChassis = 'BARE-CHASSIS'

  // TODO update after API reports wil be done
  // IMP = 'IMP',
  // EXP = 'EXP',
  // CTY = 'CTY',
  // CHS = 'CHS'
}

export const woListTableHeaders = {
  [woType.ImportRegular]: ImportRegularTableHeaders,
  [woType.ImportShuttle]: ImportRegularTableHeaders,
  [woType.ExportRegular]: ExportRegularTableHeaders,
  [woType.ExportShuttle]: ExportRegularTableHeaders,
  [woType.VanRegular]: VanRegularTableHeaders,
  [woType.CyToCy]: CyToCyTableHeaders,
  [woType.BareChassis]: BareChassisTableHeaders
};
