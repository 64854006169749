export enum AccountingTypes {
  QBARAP = 'QBARAP',
  CHASSIS = 'CHASSIS',
  PERDIEM = 'PERDIEM',
  SCHEDULE = 'SCHEDULE',
  APAR = 'APAR'
}

export const AccountingLinks = {
  [AccountingTypes.QBARAP]: 'qb-ar-ap',
  [AccountingTypes.CHASSIS]: 'chassis',
  [AccountingTypes.PERDIEM]: 'perdiem',
  [AccountingTypes.SCHEDULE]: 'schedule',
  [AccountingTypes.APAR]: 'ap-ar'
};
