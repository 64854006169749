


















































































import { Component, Vue, Prop } from 'vue-property-decorator';

import DetailInfo from './DetailInfo/DetailInfo.vue';
import AvailabilityModal from './AvailabilityModal.vue';
import BasicInfoModal from './BasicInfoModal.vue';
import BillingInfo from './BillingInfo.vue';
import DocumentModal from './DocumentModal.vue';
import LocationModal from './LocationModal.vue';
import TrackingTabModal from './TrackingTabModal.vue';
import { WorkOrder } from '../../../store/modules/workOrder/work-order.model';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import FileTable from '@/shared/components/form/FileTable.vue';
import DetailMemo from '@/pages/Main/components/DetailMemo.vue';
import WoModalSearch from '@/pages/Main/components/WoModalSearch.vue';
import AccountReceivables from '@/pages/Main/components/DetailInfo/AccountReceivables.vue';
import AccountPayables from '@/pages/Main/components/DetailInfo/AccountPayables.vue';
import { ToastHelper } from '@/utils/toast.util';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    DetailInfo,
    AvailabilityModal,
    BasicInfoModal,
    BillingInfo,
    DocumentModal,
    LocationModal,
    TrackingTabModal,
    FileTable,
    DetailMemo,
    WoModalSearch,
    AccountReceivables,
    AccountPayables
  }
})
export default class WoBaseModal extends Vue {
  @Prop() wo: WorkOrder;
  @Prop() tab: number;
  @Prop() modalId: string;
  @Prop() earlyWarning: boolean;
  @Prop() disabled: boolean;

  canDeleteAPAR = true;

  aparSynced = false;

  tabIndex: number;
  loading: boolean;
  workOrder: any = null;
  isTrackingTab = false;
  updatedAccountPayables = [];

  syncErrorModal = false;

  constructor() {
    super();
  }

  get workOrderClasses() {
    const classes = [];

    if (this.disabled) {
      classes.push('wo-disabled');
    }

    if ('reports' === this.$route.name) {
      let category: string = (this.workOrder?.category || '').split('-')[0];
      category = category.toLowerCase();

      switch (category) {
        case 'imp':
          classes.push('tms-import-colors');
          break;
        case 'exp':
          classes.push('tms-export-colors');
          break;
        case 'van':
          classes.push('tms-van-colors');
          break;
        default:
          break;
      }
    }

    return classes;
  }

  created() {
    this.loading = true;
    this.getWorkOrder(this.wo.orderNumber, this.wo.category);
  }

  searchWorkOrder(item, tab?: number) {
    this.loading = true;
    this.$forceUpdate();

    this.getWorkOrder(item.orderNumber, this.wo.category, tab);
  }

  woUpdated() {
    this.$emit('woUpdated');
    // As it is required to reload entair work order so no need refresh separately AP/AR.
    // Hence commented below line.
    //this.getAPandAR(this.wo.category, this.wo.orderNumber);
    this.searchWorkOrder(this.wo);
  }

  async getWorkOrder(orderNumber: string, category: string, tab?: number) {
    try {
      this.workOrder = await WorkOrderService.getWorkOrder(
        category,
        orderNumber
      );
      this.tabIndex = tab || this.tab;

      this.loading = false;
      this.$forceUpdate();

      this.getAPandAR(category, orderNumber);
    } catch (error) {
      this.loading = false;
      ToastHelper.show(
        'Error',
        'Error while loading work order details',
        5000,
        'danger'
      );
    }
  }

  async getAPandAR(category, orderNumber, sync?: boolean) {
    if (sync || this.tabIndex === 1) {
      try {
        await WorkOrderService.syncAPAndAR(
          this.wo.category,
          this.wo.orderNumber
        );
      } catch (e) {
        this.syncErrorModal = true;
      }

      if (this.syncErrorModal) {
        return;
      }
    }

    try {
      const [
        accountReceivables,
        accountPayables
      ] = await WorkOrderService.getWorkOrderAPandAR(category, orderNumber);

      this.workOrder.accountReceivables = accountReceivables;
      this.workOrder.accountPayables = accountPayables;
      this.$forceUpdate();
    } catch (e) {
      ToastHelper.show('Error', 'Error while loading AR/AP', 5000, 'danger');
      console.log(e);
    }
  }

  activateTab(index) {
    if (index === 1 && !this.aparSynced) {
      this.getAPandAR(this.wo.category, this.wo.orderNumber, true);
    }
  }

  updateAP(ap) {
    this.workOrder.accountPayables = ap;
    this.updatedAccountPayables = ap;
  }
}
