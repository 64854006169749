export enum DriverTypes {
  POD = 'POD',
  BILL = 'BILL',
  MANIFEST = 'MANIFEST'
}

export const DriverLinks = {
  [DriverTypes.POD]: 'pod-checklist',
  [DriverTypes.BILL]: 'bill-confirmations',
  [DriverTypes.MANIFEST]: 'manifest'
};
