



























import { Component, Vue, Prop } from 'vue-property-decorator';
import DriversSearchModal from '@/pages/Main/components/DetailInfo/DriversSearchModal.vue';

@Component({
  components: {
    DriversSearchModal
  }
})
export default class DriverInput extends Vue {
  @Prop(String) value: string;

  showModal = false;

  constructor() {
    super();
  }

  openModal() {
    this.showModal = true;
    setTimeout(() => {
      this.$bvModal.show('DriverInputSearchModal');
    }, 0);
  }

  selectDriver(id: string) {
    this.showModal = false;
    this.$emit('input', id);
  }
}
