import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { YmsService } from '@/shared/services/yms/yms.service';
import { ToastHelper } from '@/utils/toast.util';

import { YmsType } from '@/pages/YMS/models/yms.model';
import { ExcelData } from './yms.model';

@Module({
  namespaced: true,
  name: 'yms'
})
export default class YmsModule extends VuexModule {
  loading = false;
  ymsData = [];
  ymsType = '';
  excelData: ExcelData | any = {};
  filters = null;
  inventoryId = null;
  inventoryItemData = null;
  meta: any = null;

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_FILTERS(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public SET_ACCOUNTIG_DATA(payload) {
    this.ymsData = payload ? [...payload] : [];
  }

  @Mutation
  public SET_YMS_TYPE(payload) {
    this.ymsType = payload;
  }

  @Mutation
  public SET_EXCEL_DATA(payload) {
    this.excelData = payload ? { ...payload } : {};
  }

  @Mutation
  public SET_INVENTORY_DATA(payload) {
    this.inventoryItemData = payload ? { ...payload } : null;
  }

  @Mutation
  public SET_INVENTORY_ID(payload) {
    this.inventoryId = payload ? payload : null;
  }

  @Mutation
  public SET_META(payload) {
    this.meta = payload ? payload : null;
  }

  @Action
  async init() {
    this.context.commit('SET_ACCOUNTIG_DATA', null);
  }

  @Action
  async search(payload: { type?: YmsType; }) {
    const { type } = { ...payload };
    this.context.commit('SET_YMS_TYPE', type);
    // this.context.commit('SET_CATEGORY', type);
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await YmsService.searchRequest(type, this.filters);

      this.context.commit('SET_ACCOUNTIG_DATA', res.data.data);
      this.context.commit('SET_META', res.data.meta);
    } catch (err) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('YMS Error', err);
      ToastHelper.show('YMS', err.response.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async excel(acctType: YmsType) {
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await YmsService.getExcel(acctType, this.filters);
      this.context.commit('SET_EXCEL_DATA', res.data);
    } catch (e) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('YMS excel Error', e);
      ToastHelper.show('YMS excel', e.response.data?.error);
    }
    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async setFilters(filters) {
    this.context.commit('SET_FILTERS', filters);
  }

  @Action
  async test() {
    console.log('TEST TEST TEST');
  }

  @Action
  async setInventoryId(id) {
    this.context.commit('SET_INVENTORY_ID', id);
  }

  @Action
  async setInventoryItemData(data) {
    this.context.commit('SET_INVENTORY_DATA', data);
  }

  @Action
  async setYmsType(type: YmsType) {
    this.context.commit('SET_YMS_TYPE', type);
  }
}
