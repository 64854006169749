import { WoBaseModel } from './wo-base-model';

export class VanRegular extends WoBaseModel {
  overHeight = false;
  overWeight = false;
  avaDsc = true;
  avaOth = true;
  haz = false;
  masterBillOfLading = '';
  houseBillOfLading = '';
  poNumber = '';
  trailNumber = '';
  containerSize = '53';
  containerType = 'DRY';
  eta = '';
  lastFreeDay = '';
  freeDays = null;
  freeDaysType = 'Calendar';
  seal = '';
  commodity = '';
  quantity = null;
  unit = 'Unit';
  dropLive = 'DROP';
  wms = false;
  returnFreeDay = '';
}
