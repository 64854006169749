export enum Location {
  PULLOUT = 'PULLOUT',
  DELIVERY = 'DELIVERY',
  RETURN = 'RETURN'
}

export const locationMap = {
  [Location.PULLOUT]: 'pulloutLocation',
  [Location.DELIVERY]: 'deliveryLocation',
  [Location.RETURN]: 'returnLocation'
};
