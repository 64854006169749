import { DriverTypes, DriverLinks } from '@/pages/Driver/models/driver.model';

import { BaseClass } from '../base/base.service';

export class DriverProvider extends BaseClass {
  constructor() {
    // TODO update after API reports wil be renamed
    super('mobile-admin/');
  }

  async searchRequest(driverType: DriverTypes, filter?: string) {
    // limit: -1 means get ALL
    return await this.get(DriverLinks[driverType], {
      filter,
      limit: -1
    });
  }

  async getExcel(driverType: DriverTypes, filter?: string) {
    return await this.get(`${DriverLinks[driverType]}/excel`, {
      filter
    });
  }

  async approve(driverType: DriverTypes, id: string) {
    return await this.post(`${DriverLinks[driverType]}/${id}/approval`, {});
  }

  async reject(driverType: DriverTypes, id: string) {
    return await this.post(`${DriverLinks[driverType]}/${id}/rejection`, {});
  }

  async sendMessage(
    driverType: DriverTypes,
    message: { driverId: string; messageBody: string }
  ) {
    return await this.post(`${DriverLinks[driverType]}/reminder`, {
      ...message
    });
  }
}

export const DriverService = new DriverProvider();
