import { TableHeaders } from '@/shared/components/table/models';
import { boolToYN } from '@/utils/boolToYN';

export const BareChassisTableHeaders: TableHeaders[] = [
  { label: 'DATE', key: 'date' },
  { label: 'WO #', key: 'orderNumber' },
  { label: 'BILL TO', key: 'billTo' },
  {
    key: 'referenceNumber',
    label: 'REF'
  },
  {
    key: 'deliveryNumber',
    label: 'DELIVERY #'
  },
  {
    key: 'chassisNumber',
    label: 'Chassis #'
  },
  {
    key: 'chassisPool',
    rendererKey: 'equipment',
    label: 'POOL'
  },
  {
    key: 'dropLive',
    label: 'Drop/Live'
  },
  {
    key: 'hot',
    label: 'HOT',
    formatter: boolToYN
  },
  {
    key: 'pulloutLocation',
    label: 'P/OUT'
  },
  {
    key: 'pulloutSchedule',
    label: 'S IN'
  },
  {
    label: 'DILV',
    key: 'deliveryLocation'
  },
  {
    key: 'deliverySchedule',
    label: 'S IN'
  },
  {
    key: 'returnLocation',
    label: 'RTN'
  },
  {
    key: 'returnSchedule',
    label: 'S IN'
  }
];
