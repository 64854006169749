import { YmsType, YmsLinks } from '@/pages/YMS/models/yms.model';
import { BaseClass } from '../base/base.service';

export class YmsProvider extends BaseClass {
  constructor() {
    super('yms/');
  }

  async searchRequest(ymsType: YmsType, filter?: string) {
    // limit: -1 means get ALL
    return await this.get(YmsLinks[ymsType], { filter, limit: -1 });
  }

  async getExcel(ymsType: YmsType, filter?: string) {
    return await this.get(`${YmsLinks[ymsType]}/excel`, { filter });
  }

  async created(ymsType: YmsType, data) {
    return await this.post(`${YmsLinks[ymsType]}`, data);
  }

  async update(ymsType: YmsType, id: string, data) {
    return await this.put(`${YmsLinks[ymsType]}/${id}`, data);
  }

  async getInventory(id) {
    return await this.get(id);
  }

  async addInventory(formData) {
    return await this.post('', formData);
  }

  async updateInventory(formData) {
    return await this.put(formData.id, formData);
  }

  async getDamageData(id) {
    return await this.get(`/damage/${id}`);
  }

  removeDamageImage(id: string, fileId: string) {
    return this.delete(`/damage/${id}/files/${fileId}`);
  }
}

export const YmsService = new YmsProvider();
