import { BaseClass } from '../base/base.service';
import {
  BS_DATEPICKER_WITH_TIME,
  convertAPIToFormat
} from './../../../utils/date.util';
import { ToastHelper } from './../../../utils/toast.util';
import { deepClone } from './../../../utils/utils';
import {
  DispatchScheduleLocation,
  DropLiveType,
  LocationType
} from './../../models/woSchedule.model';

class JobsProvider extends BaseClass {
  constructor() {
    super('jobs');
  }

  async planning(planning) {
    try {
      const { data } = await this.put(`/planning`, planning);
      const jobPayments = deepClone(
        data.jobPayments.filter(jobPayment => !jobPayment.shouldSkip)
      );

      jobPayments.forEach(jobPayment => {
        if (
          !jobPayment.payments ||
          (jobPayment.payments && jobPayment.payments.length === 0)
        ) {
          jobPayment.payments = [
            {
              accountingCode: null,
              accountingCodeName: null,
              amount: 0
            }
          ];
        }
      });
      return { jobPlanning: data, jobPayments };
    } catch (e) {
      ToastHelper.show('Driver assign', e.message, 8000, 'danger');
    }
  }

  public convertDataTime(data) {
    const dateKeys = ['actualIn', 'actualOut'];

    data.schedules.forEach(schedule => {
      dateKeys.forEach(key => {
        if (data[key]) {
          data[key] = convertAPIToFormat(data[key], BS_DATEPICKER_WITH_TIME);
        }
      });
    });

    return data;
  }

  getDispatchJobPayments(
    data: DispatchScheduleLocation[],
    schedules,
    jobPayments?
  ) {
    const currentSchedules = this.generateDispatchCurrentSchedules(
      data,
      deepClone(schedules)
    );

    if (!jobPayments) {
      jobPayments = this.generateJobPayments(schedules);
    }

    return { previousPlan: { schedules, jobPayments }, currentSchedules };
  }

  generateJobPayments(schedules) {
    const jobPayments = [];
    const UUIDs = [...new Set(schedules.map(schedule => schedule.jobUuid))];

    UUIDs.forEach(uuid => {
      if (uuid) {
        jobPayments.push({
          jobUuid: uuid,
          shouldSkip: true,
          amount: null
        });
      }
    });

    return jobPayments;
  }

  mergeJobPayments(newJobPayments, oldJobPayments = []) {
    return [
      ...oldJobPayments.filter(
        i => !!newJobPayments.find(j => j.jobUuid === i.jobUuid)
      ),
      ...newJobPayments
    ];
  }

  private generateDispatchCurrentSchedules(
    data: DispatchScheduleLocation[],
    schedules: any[]
  ) {
    return (
      schedules
        // .filter(i => i.locationType)
        .map(i => ({
          ...i,
          ...data.find(location => location.uuid === i.uuid)
        }))
    );
  }

  async getJobsByDriverId(id) {
    const res = await this.get(`/?driver-id=${id}`);
    return res.data;
  }

  updateSchedules(wo: any, currentSchedules?: any) {
    const deliveryIndex = wo.schedules.findIndex(
      row => row.locationType === LocationType.DELIVERY
    );
    if (deliveryIndex > 0) {
      if (currentSchedules) wo.schedules = currentSchedules;
      if (wo.dropLive === DropLiveType.LIVE) {
        wo.schedules[deliveryIndex].driverId =
          wo.schedules[deliveryIndex - 1].driverId;
        wo.schedules[deliveryIndex].jobUuid =
          wo.schedules[deliveryIndex - 1].jobUuid;
      } else {
        wo.schedules[deliveryIndex].driverId = null;
        wo.schedules[deliveryIndex].jobUuid = null;
      }
    }
    return wo;
  }

  async getPodFile(jobId: string, driverId: string) {
    return this.get(`/${jobId}/pod?driver-id=${driverId}`);
  }
}

export const JobsService = new JobsProvider();
