import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { WorkOrderService } from '../../../shared/services/wo/wo-import.service';
import { WoAction } from '../../../shared/components/wo/models';
import { ToastHelper } from '../../../utils/toast.util';
import { WorkOrder } from './work-order.model';

@Module({
  namespaced: true,
  name: 'workOrderList'
})
export default class WorkOrderList extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 1;
  woType: string;
  meta: any = null;

  workOrders: WorkOrder[] | any = [];

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setWorkOrders(wos: WorkOrder[] | any) {
    this.workOrders = wos;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setWOType(woType: string) {
    this.woType = woType;
  }

  @Action
  async search(payload: { type: string }) {
    const { type } = { ...payload };
    this.context.commit('setLoadingStatus', true);
    try {
      // limit: -1 means get ALL
      const res = await WorkOrderService.get(WoAction[type], {
        filter: this.filters,
        limit: -1
      });
      const workOrders = WorkOrderService.convertWOToViewFormat(res.data.data);
      this.context.commit('setWorkOrders', workOrders);
      this.context.commit('setMetaInformation', res.data.meta);
    } catch (e) {
      console.log('workOrderImport Error', e);
      ToastHelper.show('Work Orders', e.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async excel(type: string) {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await WorkOrderService.get(`${WoAction[type]}/excel`, {
        filter: this.filters,
        page: this.page
      });
      this.context.commit('setLoadingStatus', false);
      return res.data;
    } catch (e) {
      console.log('workOrderImport Error', e);
      ToastHelper.show('Work Orders', e.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async createUser() {}
}
