import { WoBaseModel } from './wo-base-model';

export class ImportShuttle extends WoBaseModel {
  overHeight = false;
  overWeight = false;
  haz = false;
  masterBillOfLading = '';
  houseBillOfLading = '';
  poNumber = '';
  containerNumber = '';
  containerSize = '40ST';
  containerType = 'DRY';
  seal = '';
  commodity = '';
  quantity = null;
  unit = 'Unit';
  shippingLine = '';
  pulloutChassisNumber = '';
  chassisNumber = '';
  chassisPool = '';
  bondEntry = '';
  cf = '';
  dropLive = 'DROP';
  wms = false;
  streetTurn = '';
  tare = '';
}
