import { BaseClass } from '../base/base.service';

export class PermissionsProvider extends BaseClass {
  permissions: Record<string, string> = {};

  constructor() {
    super('');
  }

  async getUserPermissions() {
    const { data } = await this.get('users/curr/auths');

    if (!data || !Array.isArray(data)) {
      throw new Error('Server does not return the user permissions list');
    }

    this.generatePermissions(data);
    return data;
  }

  can(area, object, action: string) {
    return this.permissions[`${area} ${object}`] === action;
  }

  private generatePermissions(permissions) {
    permissions.forEach(
      ({ area, object, action }) =>
        (this.permissions[`${area} ${object}`] = action)
    );
  }
}

export const PermissionsService = new PermissionsProvider();
