import { WoBaseModel } from './wo-base-model';

export class CyToCy extends WoBaseModel {
  overHeight = false;
  overWeight = false;
  haz = false;
  masterBillOfLading = '';
  houseBillOfLading = '';
  poNumber = '';
  containerNumber = '';
  containerSize = '40ST';
  containerType = 'DRY';
  eta = '';
  lastFreeDay = '';
  freeDays = null;
  freeDaysType = 'Calendar';
  seal = '';
  commodity = '';
  quantity = null;
  unit = 'Unit';
  shippingLine = '';
  chassisNumber = '';
  pulloutChassisNumber = '';
  chassisPool = '';
  dropLive = 'DROP';
  remarks = '';
  sendTo = '';
  wms = false;
  tare = '';
  returnFreeDay = '';
}
