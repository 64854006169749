







































































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ArTable extends Vue {
  constructor() {
    super();
  }
}
