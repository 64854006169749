import { UserManagementService } from '@/shared/services/user-management/user-management.service';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { ToastHelper } from '../../../utils/toast.util';
import { User } from './user-management.model';

@Module({
  namespaced: true,
  name: 'userManagement'
})
export default class UserManagementModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 1;
  meta: any = null;

  users: User[] | any = [];
  user: User = null;

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setUsers({ data, meta }) {
    this.users = data;
    this.meta = meta;
  }

  @Mutation
  public getUsers() {
    return this.users;
  }

  @Mutation
  public setUser({ data }) {
    this.user = data;
  }

  @Mutation
  public getUser() {
    return this.user;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setPage(page: number) {
    this.page = page;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await UserManagementService.getUsers(null);
      this.context.commit('setUsers', res);
      console.log(res);
    } catch (err) {
      console.log('usersImport Error', err);
      ToastHelper.show('User', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search(limit?) {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await UserManagementService.getUsers(
        this.filters,
        this.page,
        limit
      );
      this.context.commit('setUsers', res);
    } catch (err) {
      console.log('usersImport Error', err);
      ToastHelper.show('User', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async getUserById(id: string) {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await UserManagementService.getUserById(
        id
      );
      this.context.commit('setUser', res);
    } catch (err) {
      ToastHelper.show('User', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async delete(userId: any) {
    this.context.commit('setLoadingStatus', true);
    try {
      await UserManagementService.deleteUserById(userId);
      const userIndex = this.users.findIndex(user => user.id === userId);
      this.users.splice(userIndex, 1);
    } catch (err) {
      console.log('User Deletion Error', err);
      ToastHelper.show('User Deletion', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async passwordReset(userId: any) {
    this.context.commit('setLoadingStatus', true);
    try {
      await UserManagementService.passwordReset(userId);
    } catch (err) {
      console.log('Password Reset Error', err);
      ToastHelper.show('Password Reset', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }
}
