import { convertDateToFormat, BS_DATEPICKER_FORMAT } from '@/utils/date.util';

const holidaysUS2021 = [
  '2021-01-01', // New Year’s Day
  '2021-01-18', // Birthday of Martin Luther King, Jr.
  '2021-01-20', // Inauguration Day.
  '2021-02-15', // Washington’s Birthday.
  '2021-05-31', // Memorial Day
  '2021-06-18', // Juneteenth National Independence Day
  '2021-07-05', // Independence Day
  '2021-09-06', // Labor Day
  '2021-10-11', // Columbus Day
  '2021-11-11', // Veterans Day
  '2021-11-25', // Thanksgiving Day
  '2021-12-24' // Christmas Day
];

const holidaysUS2022 = [
  '2021-12-31', // New Year’s Day
  '2022-01-17', // Birthday of Martin Luther King, Jr.
  '2022-02-21', // Washington’s Birthday.
  '2022-05-30', // Memorial Day
  '2022-07-04', // Independence Day
  '2022-09-05', // Labor Day
  '2022-10-10', // Columbus Day
  '2022-11-11', // Veterans Day
  '2022-11-24', // Thanksgiving Day
  '2022-12-26' // Christmas Day
];

const holidaysUS2023 = [
  '2023-01-02', // New Year’s Day
  '2023-01-16', // Birthday of Martin Luther King, Jr.
  '2023-02-20', // Washington’s Birthday.
  '2023-05-29', // Memorial Day
  '2023-07-04', // Independence Day
  '2023-09-04', // Labor Day
  '2023-10-09', // Columbus Day
  '2023-11-10', // Veterans Day
  '2023-11-23', // Thanksgiving Day
  '2023-12-25' // Christmas Day
];

const holidaysUS2024 = [
  '2024-01-01', // New Year’s Day
  '2024-01-15', // Birthday of Martin Luther King, Jr.
  '2024-02-19', // Washington’s Birthday.
  '2024-05-27', // Memorial Day
  '2024-07-04', // Independence Day
  '2024-09-02', // Labor Day
  '2024-10-14', // Columbus Day
  '2024-11-11', // Veterans Day
  '2024-11-28', // Thanksgiving Day
  '2024-12-25' // Christmas Day
];

const holidaysUS2025 = [
  '2025-01-01', // New Year’s Day
  '2025-01-20', // Birthday of Martin Luther King, Jr.
  '2025-01-20', // Inauguration Day
  '2025-02-17', // Washington’s Birthday.
  '2025-05-26', // Memorial Day
  '2025-07-04', // Independence Day
  '2025-09-01', // Labor Day
  '2025-10-13', // Columbus Day
  '2025-11-11', // Veterans Day
  '2025-11-27', // Thanksgiving Day
  '2025-12-25' // Christmas Day
];

const holidaysUS2026 = [
  '2026-01-01', // New Year’s Day
  '2026-01-19', // Birthday of Martin Luther King, Jr.
  '2026-02-16', // Washington’s Birthday.
  '2026-05-25', // Memorial Day
  '2026-07-03', // Independence Day
  '2026-09-07', // Labor Day
  '2026-10-12', // Columbus Day
  '2026-11-11', // Veterans Day
  '2026-11-26', // Thanksgiving Day
  '2026-12-25' // Christmas Day
];

const holidaysUS2027 = [
  '2027-01-01', // New Year’s Day
  '2027-01-18', // Birthday of Martin Luther King, Jr.
  '2027-02-15', // Washington’s Birthday.
  '2027-05-31', // Memorial Day
  '2027-07-05', // Independence Day
  '2027-09-06', // Labor Day
  '2027-10-11', // Columbus Day
  '2027-11-11', // Veterans Day
  '2027-11-25', // Thanksgiving Day
  '2027-12-24' // Christmas Day
];

const holidaysUS2028 = [
  '2027-12-31', // New Year’s Day
  '2028-01-17', // Birthday of Martin Luther King, Jr.
  '2028-02-21', // Washington’s Birthday.
  '2028-05-29', // Memorial Day
  '2028-07-04', // Independence Day
  '2028-09-04', // Labor Day
  '2028-10-09', // Columbus Day
  '2028-11-10', // Veterans Day
  '2028-11-23', // Thanksgiving Day
  '2028-12-25' // Christmas Day
];

const holidaysUS2029 = [
  '2029-01-01', // New Year’s Day
  '2029-01-15', // Birthday of Martin Luther King, Jr.
  '2029-02-19', // Washington’s Birthday.
  '2029-05-28', // Memorial Day
  '2029-07-04', // Independence Day
  '2029-09-03', // Labor Day
  '2029-10-08', // Columbus Day
  '2029-11-12', // Veterans Day
  '2029-11-22', // Thanksgiving Day
  '2029-12-25' // Christmas Day
];

const holidaysUS2030 = [
  '2030-01-01', // New Year’s Day
  '2030-01-21', // Birthday of Martin Luther King, Jr.
  '2030-02-18', // Washington’s Birthday.
  '2030-05-27', // Memorial Day
  '2030-07-04', // Independence Day
  '2030-09-02', // Labor Day
  '2030-10-14', // Columbus Day
  '2030-11-11', // Veterans Day
  '2030-11-28', // Thanksgiving Day
  '2030-12-25' // Christmas Day
];

const holidays: Set<string> = new Set([
  ...holidaysUS2021,
  ...holidaysUS2022,
  ...holidaysUS2023,
  ...holidaysUS2024,
  ...holidaysUS2025,
  ...holidaysUS2026,
  ...holidaysUS2027,
  ...holidaysUS2028,
  ...holidaysUS2029,
  ...holidaysUS2030
]);

/**
 * @param date: date to check
 */
export function isHoliday(date: Date): boolean {
  return holidays.has(convertDateToFormat(date, BS_DATEPICKER_FORMAT));
}
