import Vue from 'vue';

import { CurrencyFilter } from './../../utils/currency';

Vue.filter('currency', function(value) {
  if (typeof value !== 'number') {
    return value;
  }

  return CurrencyFilter.format(value).split('$')[1];
});
