import {
  helpers,
  required,
  integer,
  maxLength
} from 'vuelidate/lib/validators';

export const accPayablesValidations: any = {
  $each: {
    type: { required },
    name: { required },
    driverName: { required },
    amount: {},
    remark: { maxLength: maxLength(128) }
  }
};

export const accReceivablesValidations: any = {
  $each: {
    billToName: { required },
    name: { required },
    quantity: { integer, maxLength: maxLength(5) },
    rate: {},
    remark: { maxLength: maxLength(128) }
  }
};
