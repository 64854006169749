import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { chass, email } from './wo-validation';

export const BareChassisValidation = {
  billTo: { required },
  referenceNumber: { required, maxLength: maxLength(30) },
  deliveryNumber: { maxLength: maxLength(20) },
  chassisNumber: { chass, required },
  sendTo: { maxLength: maxLength(256), email },
  pulloutLocation: { required },
  deliveryLocation: { required },
  returnLocation: {
    required: requiredIf(form => !!form.returnSchedule)
  },
  dropLive: { required },
  do: { required },
  remarks: { maxLength: maxLength(30) },
  pulloutSchedule: {},
  deliverySchedule: {},
  returnSchedule: {},
  chassisPool: { required: requiredIf(form => !!form.chassisNumber) }
};
