import { BaseWO } from './wo.model';
import { WoBaseModel } from './wo-base-model';

export class ImportRegular extends WoBaseModel {
  avaDsc = false;
  avaObl = false;
  avaCus = false;
  avaOth = false;
  vessel = '';
  overHeight = false;
  overWeight = false;
  haz = false;
  masterBillOfLading = '';
  houseBillOfLading = '';
  poNumber = '';
  containerNumber = '';
  containerSize = '40ST';
  containerType = 'DRY';
  eta = '';
  lastFreeDay = '';
  freeDays = null;
  freeDaysType = 'Calendar';
  seal = '';
  commodity = '';
  quantity = null;
  unit = 'Unit';
  shippingLine = '';
  pulloutChassisNumber = '';
  chassisNumber = '';
  chassisPool = '';
  bondEntry = '';
  cf = '';
  dropLive = 'DROP';
  bk = '';
  avaDate = '';
  wms = false;
  streetTurn = '';
  cutOff = '';
  tare = '';
  returnFreeDay = '';
  erd = '';
}

export const importRegularMock: BaseWO = {
  category: 'IMP-SHUTTLE',
  billTo: 'Test Customer',
  masterBillOfLading: 'MBL#00000123',
  houseBillOfLading: 'HBL#0001234',
  referenceNumber: 'REF001',
  poNumber: 'PO010101',
  deliveryNumber: 'DELIVERY_002',
  containerNumber: 'CNTR0181811',
  containerSize: '53',
  containerType: 'REEFER',
  shippingLine: 'COSCO',
  vessel: 'Big Cargo Ship',
  chassisNumber: 'ABCD123456',
  chassisPool: '',
  eta: '2020-12-15',
  avaDsc: true,
  avaObl: true,
  avaCus: true,
  avaOth: true,
  lastFreeDay: '2020-12-17',
  freeDays: 8,
  freeDaysType: 'Calendar',
  seal: 'ABCD012EFG345',
  commodity: 'Test Commodity',
  weight: 1500,
  quantity: 1024,
  unit: 'Carton',
  sendTo: 'send_to@email.com',
  remarks: 'This is the long comment with remark',
  pulloutLocation: 'Ocean Terminal',
  pulloutSchedule: '2020-12-15',
  deliveryLocation: 'FleetUp',
  deliverySchedule: '2020-12-17',
  returnLocation: 'Terminal California',
  returnSchedule: '2020-12-19',
  overHeight: true,
  overWeight: true,
  haz: true,
  hot: true,
  dropLive: 'DROP',
  bondEntry: 'Test bond entity',
  do: {
    filename: 'hello.txt',
    body: 'SGVsbG8gVE1T'
  },
  cf: {
    filename: 'cf.txt',
    body: 'Q0Ygb3B0aW9uYWwgZG9jdW1lbnQ='
  },
  wms: true
};
