import { UserModule } from '@/store';
import { NavigationGuardNext, Route } from 'vue-router';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.matched.some(record => record.meta.requiresNotLogin)) {
    // if (UserModule.isAuthorized) {
    //   next({
    //     path: '/'
    //   });
    // }
  }
  next();
};
