import {
  DispatchScheduleLocation,
  LocationType
} from '@/shared/models/woSchedule.model';
import { BaseWO } from '@/shared/components/wo/models/wo.model';

export function getScheduleOrderErrors(
  schedules: DispatchScheduleLocation[]
): string[] {
  const errors: string[] = [];
  const dates: Map<LocationType, number> = getDatesMap(schedules);

  if (
    dates.has(LocationType.DELIVERY) &&
    dates.has(LocationType.PULLOUT) &&
    dates.get(LocationType.DELIVERY) < dates.get(LocationType.PULLOUT)
  ) {
    errors.push(
      'The schedule delivery date can not be less than the pullout date'
    );
  }

  if (
    dates.has(LocationType.PICKUP) &&
    dates.has(LocationType.DELIVERY) &&
    dates.get(LocationType.PICKUP) < dates.get(LocationType.DELIVERY)
  ) {
    errors.push(
      'The schedule pickup date can not be less than the delivery date or equal'
    );
  }

  if (
    dates.has(LocationType.RETURN) &&
    dates.has(LocationType.PICKUP) &&
    dates.get(LocationType.RETURN) <= dates.get(LocationType.PICKUP)
  ) {
    errors.push(
      'The schedule return date can not be less than the pickup date or equal'
    );
  }

  if (
    !dates.has(LocationType.PICKUP) &&
    dates.has(LocationType.RETURN) &&
    dates.has(LocationType.DELIVERY) &&
    dates.get(LocationType.RETURN) <= dates.get(LocationType.DELIVERY)
  ) {
    errors.push(
      'The schedule return date can not be less than the delivery date or equal'
    );
  }

  return errors;
}

export function getScheduleMandatoryFieldsErrors(
  schedules: DispatchScheduleLocation[],
  requiredFields: Set<LocationType>
): string[] {
  const errors: string[] = [];
  const dates: Map<LocationType, number> = getDatesMap(schedules);

  if (
    requiredFields.has(LocationType.PULLOUT) &&
    !dates.get(LocationType.PULLOUT)
  ) {
    errors.push('The schedule pullout date is a mandatory field');
  }

  if (
    requiredFields.has(LocationType.DELIVERY) &&
    !dates.get(LocationType.DELIVERY)
  ) {
    errors.push('The schedule delivery date is a mandatory field');
  }

  if (
    requiredFields.has(LocationType.RETURN) &&
    !dates.get(LocationType.RETURN)
  ) {
    errors.push('The schedule return date is a mandatory field');
  }

  if (
    requiredFields.has(LocationType.PICKUP) &&
    !dates.get(LocationType.PICKUP)
  ) {
    errors.push('The schedule pickup date is a mandatory field');
  }

  return errors;
}

export function getScheduleLocationErrors(
  wo: BaseWO & { schedules: DispatchScheduleLocation[] }
): string[] {
  if (!wo) {
    throw new Error('Work order can not be empty');
  }

  if (!Array.isArray(wo.schedules)) {
    throw new Error('wo.schedules has to be array');
  }

  const schedulesMap = wo.schedules.reduce(
    (
      accumulator: Map<LocationType, DispatchScheduleLocation>,
      currentValue: DispatchScheduleLocation
    ) => {
      accumulator.set(currentValue.locationType, currentValue);

      return accumulator;
    },
    new Map()
  );

  const errors: string[] = [];

  if (wo.pulloutSchedule && !schedulesMap.has(LocationType.PULLOUT)) {
    errors.push(
      'A pullout location is empty. Please, edit a pullout schedule by the Work Order form.'
    );
  }

  if (wo.deliverySchedule && !schedulesMap.has(LocationType.DELIVERY)) {
    errors.push(
      'A delivery location is empty. Please, edit a delivery schedule by the Work Order form.'
    );
  }

  if (wo.returnSchedule && !schedulesMap.has(LocationType.RETURN)) {
    errors.push(
      'A return location is empty. Please, edit a return schedule by the Work Order form.'
    );
  }

  if (wo.pickupSchedule && !schedulesMap.has(LocationType.PICKUP)) {
    errors.push(
      'A pickup location is empty. Please, edit a pickup schedule by the Work Order form.'
    );
  }

  return errors;
}

function getDatesMap(
  schedules: DispatchScheduleLocation[]
): Map<LocationType, number> {
  return schedules.reduce(
    (
      accumulator: Map<LocationType, number>,
      currentValue: DispatchScheduleLocation
    ) => {
      const timeNumber = parseInt(
        currentValue.scheduleInTime
          ? currentValue.scheduleInTime.replaceAll(':', '')
          : '0'
      );
      const dateNumber =
        parseInt(currentValue.scheduleIn) +
        (isNaN(timeNumber) ? 0 : timeNumber);

      if (!isNaN(dateNumber)) {
        accumulator.set(currentValue.locationType, dateNumber);
      }

      return accumulator;
    },
    new Map()
  );
}

export function errorByLocation(schedules: DispatchScheduleLocation[]) {
  const errors: any[] = [];
  const dates: Map<LocationType, number> = getDatesMap(schedules);

  // if (!dates.has(LocationType.PULLOUT)) {
  //   errors.push({
  //     message: 'This is a required field. Enter a valid date.',
  //     locationType: LocationType.PULLOUT
  //   });
  // }

  // if (!dates.has(LocationType.DELIVERY)) {
  //   errors.push({
  //     message: 'This is a required field. Enter a valid date.',
  //     locationType: LocationType.DELIVERY
  //   });
  // }

  if (
    dates.has(LocationType.DELIVERY) &&
    dates.has(LocationType.PULLOUT) &&
    dates.get(LocationType.DELIVERY) < dates.get(LocationType.PULLOUT)
  ) {
    errors.push({
      message:
        'The schedule delivery date can not be less than the pullout date',
      locationType: LocationType.DELIVERY
    });
  }

  if (
    dates.has(LocationType.PICKUP) &&
    dates.has(LocationType.DELIVERY) &&
    dates.get(LocationType.PICKUP) < dates.get(LocationType.DELIVERY)
  ) {
    errors.push({
      message:
        'The schedule pickup date can not be less than the delivery date or equal',
      locationType: LocationType.PICKUP
    });
  }

  if (
    dates.has(LocationType.RETURN) &&
    dates.has(LocationType.PICKUP) &&
    dates.get(LocationType.RETURN) <= dates.get(LocationType.PICKUP)
  ) {
    errors.push({
      message:
        'The schedule return date can not be less than the pickup date or equal',
      locationType: LocationType.RETURN
    });
  }

  if (
    !dates.has(LocationType.PICKUP) &&
    dates.has(LocationType.RETURN) &&
    dates.has(LocationType.DELIVERY) &&
    dates.get(LocationType.RETURN) <= dates.get(LocationType.DELIVERY)
  ) {
    errors.push({
      message:
        'The schedule return date can not be less than the delivery date or equal',
      locationType: LocationType.RETURN
    });
  }

  return errors;
}
