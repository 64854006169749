















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { WorkOrderModule } from '@/store/index';
import { WoType } from '../models/index';
import { TableHeaders } from '../../table/models';
import { woListTableHeaders } from '../models/workOrderList/index';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { WorkOrder } from '@/shared/models/wo.model';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';

@Component({
  components: { WoBaseModal }
})
export default class WorkOrderList extends Vue {
  @Prop({ default: WoType.ImportRegular }) woType: WoType;

  workOrderModule = WorkOrderModule;
  selectedWo: WorkOrder = null;

  classes: string[] = [];

  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detailInfo'
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billingInfo'
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document'
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking'
    },
    {
      type: 'divider'
    },
    {
      name: this.$i18n.t('contextMenu.basicInfo'),
      slug: 'basicInfo'
    },
    {
      name: this.$i18n.t('contextMenu.location'),
      slug: 'location'
    },
    {
      name: this.$i18n.t('contextMenu.availability'),
      slug: 'availability'
    },
    {
      name: this.$i18n.t('contextMenu.schedule'),
      slug: 'schedule'
    }
  ];

  constructor() {
    super();
    this.workOrderModule.getTodaysWorkOrders(this.woType);
  }

  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.classes = generateRouterClasses(this.$route);
  }
  get headersList(): TableHeaders[] {
    return woListTableHeaders[this.$route.params.type];
  }

  rowRightClicked(item: any, index: number, evt: Event) {
    evt.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(evt, item);
  }

  onContextMenuOptionSelect(event: Event, item: any) {
    console.log('onContextMenuOptionSelect', event);
  }

  openWorkOrderDetail(data: WorkOrder) {
    this.selectedWo = data;
    this.$bvModal.show('wo-base-modal');
  }
}
