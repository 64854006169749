





















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DATE_TIME_EMAIL_FORMAT } from '@/utils/date.util';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { ToastHelper } from '@/utils/toast.util';
import { UserModule } from '@/store';

export interface Memo {
  acctId: string;
  orderNumber: string;
  fieldName: string;
  rowId: string;
  createdDate: string;
  createdBy: string;
  text: string;
}

@Component({})
export default class WOMemo extends Vue {
  @Prop() memos: Memo[];
  @Prop({ default: false }) hideNewBox: boolean;
  @Prop() wo;
  @Prop() uuId;
  @Prop() newMemo;
  @Prop() newBK;
  @Prop() memoType;

  successMsg = this.$i18n.t('wo.memoSavedSuccessfully');
  woLabel: any = this.$i18n.t('wo.workOrder');

  @Prop() memo: string;

  text = null;
  DATE_TIME_EMAIL_FORMAT = DATE_TIME_EMAIL_FORMAT;
  userModule = UserModule;

  constructor() {
    super();
  }

  memoFormatter(e) {
    return String(e).substring(0, 128);
  }

  updateModel(value: string) {
    this.$emit('input', value);
  }

  udpateMemo({ fieldName, createdDate, rowId, text }) {
    WorkOrderService.udpateMemo(this.wo.category, this.wo.orderNumber, {
      fieldName,
      createdDate,
      rowId,
      text
    })
      .then(() => {
        ToastHelper.show(
          this.woLabel,
          `${this.woLabel} ${this.wo.orderNumber} booking ${this.successMsg}`,
          8000,
          'success'
        );
      })
      .catch(e => {
        ToastHelper.show('Can not save memo', e.message, 8000, 'danger');
      });
  }

  saveMemo(memo) {
    const data = {
      text: memo.acctId ? memo.text : memo,
      rowId: this.memoType === 'booking' ? null : this.uuId,
      fieldName: this.memoType === 'booking' ? 'BOOKING_NUMBER' : 'SCHEDULE'
    };

    WorkOrderService.saveMemo(this.wo.category, this.wo.orderNumber, data)
      .then(memo => {
        this.$emit('refreshWorkOrder');
        ToastHelper.show(
          this.woLabel,
          `${this.woLabel} ${this.wo.orderNumber} booking ${this.successMsg}`,
          8000,
          'success'
        );
        const newMemo = {
          ...memo
        };
        if (this.memoType === 'booking') {
          this.wo.bookingNumberMemos.push(newMemo);
        } else {
          const index = this.wo.schedules.findIndex(
            item => item.uuid === this.uuId
          );
          this.wo.schedules[index].memos.push(newMemo);
          this.text = '';
        }
      })
      .catch(e => {
        ToastHelper.show('BookingNumberMemo', e.message, 8000, 'danger');
      });
  }
}
