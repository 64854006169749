import { render, staticRenderFns } from "./ArTable.vue?vue&type=template&id=3230ae00&scoped=true&"
import script from "./ArTable.vue?vue&type=script&lang=ts&"
export * from "./ArTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3230ae00",
  null
  
)

export default component.exports