export const removeEmptyFormProperties = data => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      delete data[key];
    }
  });
};

export const convertEmptyStringToNull = data => {
  Object.keys(data).forEach(key => {
    if (data[key] === '') {
      data[key] = null;
    }
  });
};

export const convertStringsToInteger = (
  data: Record<string, any>,
  fields: string[]
) => {
  fields.forEach(field => {
    if (Object.prototype.hasOwnProperty.call(data, field)) {
      data[field] = parseInt(data[field]);
    }
  });
};

export const arrayConvertStringsToInteger = (data: any[], fields: string[]) => {
  data.forEach(row => {
    fields.forEach(field => {
      if (Object.prototype.hasOwnProperty.call(row, field)) {
        row[field] = parseInt(row[field]);
      }
    });
  });
};

export const convertFilesToAPIFormat = (
  data: Record<string, any>,
  fields: string[]
) => {
  fields.forEach(field => {
    if (
      Object.prototype.hasOwnProperty.call(data, field) &&
      data[field].filename
    ) {
      const category = field.toUpperCase();

      data.files.push({
        filename: data[field].filename,
        category,
        tempS3FileName: data[field].tempS3FileName,
        tempS3PreSignedUrl: data[field].tempS3PreSignedUrl
      });
      delete data[field];
    }
  });
};
