export const deepClone = data => JSON.parse(JSON.stringify(data));

export const removeFields = (obj, fields: string[], type) => {
  const data = deepClone(obj);
  if (type === 'IMP-REG' || type === 'IMP-SHUTTLE') {
    fields = fields.filter(e => e !== 'avaDate');
  }
  fields.forEach(key => delete data[key]);
  return data;
};

export const removeFieldsFromArray = (arr, fields: string[]) => {
  const data = deepClone(arr);
  data.forEach(i => fields.forEach(key => delete i[key]));
  return data;
};

export function phoneFormater(rawPhoneText: string): string {
  if (rawPhoneText) {
    const phoneNumbers = [...rawPhoneText];
    phoneNumbers.splice(0, 0, '(');
    phoneNumbers.splice(4, 0, ')');
    phoneNumbers.splice(5, 0, ' ');
    phoneNumbers.splice(9, 0, '-');
    return phoneNumbers.join('');
  } else {
    return rawPhoneText;
  }
}

export function uuid() {
  return (
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substring(2)
  );
}

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function getLanguageCode(key){
  switch (key) {
    case 'en':
      return 1;
    case 'es':
      return 2;
    default:
      return 1;
  }
}
