import { BToast } from 'bootstrap-vue';

class Toast {
  show(title: string, message: string, delay = 5000, variant = null) {
    let bootStrapToaster = new BToast();

    bootStrapToaster.$bvToast.toast(message, {
      title,
      toaster: 'b-toaster-top-right',
      solid: true,
      appendToast: false,
      autoHideDelay: delay,
      variant: variant
    });
  }
}

export const ToastHelper = new Toast();
