





























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { Driver } from '@/shared/models/driver.model';
import { BIconPlusCircle, BIconTrash } from 'bootstrap-vue';

@Component({
  components: { BIconPlusCircle, BIconTrash }
})
export default class APInputModal extends Vue {
  @Prop({ default: '' }) jobPayments: object;

  model: any = [
    {
      jobUuid: '',
      location: '',
      driverId: '',
      shouldSkip: false,
      amount: null
    }
  ];

  activeIndex = null;

  constructor() {
    super();
  }

  mounted() {
    this.model = JSON.parse(JSON.stringify(this.jobPayments));
    console.log(this.model);
  }

  save() {
    let changes = [];
    this.model.forEach(elem => {
      changes = [
        ...changes,
        {
          jobUuid: elem.jobUuid,
          amount: elem.amount
        }
      ];
    });
    this.$emit('select', changes);
    this.hide();
  }

  hide() {
    this.$bvModal.hide('APInputModal');
  }

  // add(index: number) {
  //   this.model.splice(index + 1, 0, {});
  // }

  remove(index: number) {
    this.model.splice(index, 1);
  }
}
