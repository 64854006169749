




































import { Component, Vue, Prop } from 'vue-property-decorator';
import WorkOrderForm from '@/shared/components/wo/WorkOrderForm.vue';
import ScheduleArea from './ScheduleArea.vue';

import WoAction from './WoAction.vue';
import { JobPayments } from '@/shared/models/driverIdModal.model';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { LocationType } from '@/shared/models/woSchedule.model';

@Component({
  components: {
    WorkOrderForm,
    ScheduleArea,
    WoAction
  }
})
export default class DetailInfo extends Vue {
  @Prop() wo;
  @Prop() earlyWarning: boolean;

  editedForm: any = {};
  vForm: any = {};
  driverIdToChange = {};
  putRequest: JobPayments = {
    previousPlan: {
      schedules: [],
      jobPayments: []
    },
    currentSchedules: []
  };

  changeDriverId(driverInfo: { prevValue: string; value: string }) {
    this.driverIdToChange = { ...driverInfo };
  }

  locationChanged({ key, value }: { key: string; value: string }) {
    this.wo[key] = value;
  }

  dropLiveChanged(dropLive) {
    this.wo.dropLive = dropLive;
  }

  updateAP(ap) {
    this.$emit('updateAP', ap);
  }

  constructor() {
    super();
  }

  async updateBookingData(bookingData) {
    Object.keys(bookingData).forEach(key => {
      if (key in this.wo && bookingData[key] !== null) {
        this.wo[key] = bookingData[key];
      }
    });

    let pulloutLocationInfo = null;
    let deliveryLocationInfo = null;
    let returnLocationInfo = null;

    if (bookingData.pulloutLocationName) {
      pulloutLocationInfo = await CompanyService.getCompaniesIdentifiers(
        bookingData.pulloutLocationName
      );
    }
    if (bookingData.deliveryLocationName) {
      deliveryLocationInfo = await CompanyService.getCompaniesIdentifiers(
        bookingData.deliveryLocationName
      );
    }
    if (bookingData.returnLocationName) {
      returnLocationInfo = await CompanyService.getCompaniesIdentifiers(
        bookingData.returnLocationName
      );
    }

    this.wo.schedules.forEach(schedule => {
      if (
        pulloutLocationInfo &&
        schedule.locationType === LocationType.PULLOUT
      ) {
        schedule.location = pulloutLocationInfo[0].id;
        schedule.locationName = pulloutLocationInfo[0].name;
        schedule.state = pulloutLocationInfo[0].state;
        schedule.city = pulloutLocationInfo[0].city;
      }

      if (
        (deliveryLocationInfo &&
          schedule.locationType === LocationType.DELIVERY) ||
        schedule.locationType === LocationType.PICKUP
      ) {
        //PICKUP loaction must be same as DELIVERY
        schedule.location = deliveryLocationInfo[0].id;
        schedule.locationName = deliveryLocationInfo[0].name;
        schedule.state = deliveryLocationInfo[0].state;
        schedule.city = deliveryLocationInfo[0].city;
      }

      if (returnLocationInfo && schedule.locationType === LocationType.RETURN) {
        schedule.location = returnLocationInfo[0].id;
        schedule.locationName = returnLocationInfo[0].name;
        schedule.state = returnLocationInfo[0].state;
        schedule.city = returnLocationInfo[0].city;
      }
    });
  }
}
