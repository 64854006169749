import {
  AccountingTypes,
  AccountingLinks
} from '@/pages/Accounting/models/accounting.model';
import { BaseClass } from '../base/base.service';

export class AccountingProvider extends BaseClass {
  constructor() {
    super('accountings/');
  }

  async searchRequest(acctType: AccountingTypes, filter?: string) {
    // limit: -1 means get ALL
    return await this.get(AccountingLinks[acctType], { filter, limit: -1 });
  }

  async getExcel(acctType: AccountingTypes, filter?: string) {
    return await this.get(`${AccountingLinks[acctType]}/excel`, { filter });
  }

  async matchQB(data) {
    return await this.post(
      `${AccountingLinks[AccountingTypes.QBARAP]}/fetch-from-qb`,
      data
    );
  }

  async QB(data) {
    return await this.post(
      `${AccountingLinks[AccountingTypes.QBARAP]}/qb`,
      data
    );
  }
}

export const AccountingService = new AccountingProvider();
