import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { Locales } from './@types/i18n.enum';
import { en } from './en/en';
import { es } from './es/es';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    es
  }
});

export function setI18nLanguage(locale: Locales) {
  i18n.locale = locale;
  localStorage.setItem('appLanguage', locale);
  window.location.reload();
}
