


























import { Component, Prop } from 'vue-property-decorator';
import { WoModalBaseClass } from './WoModalBase.class';
import AccountReceivables from './DetailInfo/AccountReceivables.vue';
import AccountPayables from './DetailInfo/AccountPayables.vue';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    AccountReceivables,
    AccountPayables
  }
})
export default class BillingInfo extends WoModalBaseClass {
  @Prop({}) updateAP: any;
  successMsg = this.$i18n.t('wo.savedSuccessfully');
  woLabel = this.$i18n.t('wo.workOrder');

  canDeleteAPAR = true;

  constructor() {
    super();
  }

  created() {
    this.canDeleteAPAR = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'AP/AR DEL',
      'A/E'
    );
  }
}
