import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { CompanyService } from '../../../shared/services/mater-data/company.service';
import { ToastHelper } from '../../../utils/toast.util';
import { Company } from './company.model';

@Module({
  namespaced: true,
  name: 'company'
})
export default class CompanyModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  page = 0;
  empType: string;
  meta: any = null;

  companies: Company[] | any = [];

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setCompanies({ data, meta }) {
    this.companies = data.filter(x => x);
    this.meta = meta;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setEmpType(empType: string) {
    this.empType = empType;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await CompanyService.getCompanies();
      this.context.commit('setCompanies', res);
    } catch (err) {
      console.log('companiesImport Error', err);
      ToastHelper.show('Company', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await CompanyService.getCompanies(this.filters);
      this.context.commit('setCompanies', res);
    } catch (err) {
      console.log('companiesImport Error', err);
      ToastHelper.show('Company', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }
}
