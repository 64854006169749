import { BaseWO } from './wo.model';

export class WoBaseModel implements BaseWO {
  category = '';
  billTo = '';
  billToName = '';
  referenceNumber = '';
  weight = null;
  deliveryNumber = '';
  sendTo = '';
  remarks = '';
  pulloutLocation = '';
  pulloutLocationName = '';
  pulloutSchedule = '';
  pickupSchedule = '';
  deliveryLocation = '';
  deliveryLocationName = '';
  deliverySchedule = '';
  returnLocation = '';
  returnLocationName = '';
  returnSchedule = '';
  hot = false;
  dropLive = '';
  do = {};
  chassisNumber = '';
  wms = false;
}
