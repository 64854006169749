import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { MasterDataService } from '../../../shared/services/mater-data/master-data.service';
import { ToastHelper } from '../../../utils/toast.util';
import { Account } from './account.model';

@Module({
  namespaced: true,
  name: 'account'
})
export default class AccountModule extends VuexModule {
  loading = false;
  errorMessage: any = null;

  filters: string = null;
  empType: string;
  meta: any = null;

  accounts: Account[] | any = [];

  @Mutation
  public setLoadingStatus(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public setErrorMessage(message: any) {
    this.errorMessage = message;
  }

  @Mutation
  public setAccounts({ data, meta }) {
    this.accounts = data;
    this.meta = meta;
  }

  @Mutation
  public setMetaInformation(meta: any) {
    this.meta = meta;
  }

  @Mutation
  public setFilters(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public setEmpType(empType: string) {
    this.empType = empType;
  }

  @Action
  async load() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await MasterDataService.getGLCodes();
      this.context.commit('setAccounts', res);
    } catch (err) {
      console.log('accountsImport Error', err);
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async search() {
    this.context.commit('setLoadingStatus', true);
    try {
      const res = await MasterDataService.getGLCodes(this.filters);
      this.context.commit('setAccounts', res);
    } catch (err) {
      console.log('accountsImport Error', err);
      ToastHelper.show('Account', err.response.data?.error);
    }
    this.context.commit('setLoadingStatus', false);
  }
}
