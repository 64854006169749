import { UserCreate } from './../../store/modules/user/user.model';
import { BaseClass } from './base/base.service';
class Auth extends BaseClass {
  constructor() {
    super('users');
  }

  rememberMe = true;

  createUser(userCreate: UserCreate) {
    return this.post('', userCreate);
  }

  getCurrentDivisions() {
    return this.get('/curr/divisions');
  }
}

export const AuthService = new Auth();
