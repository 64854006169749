import Vue from 'vue';

Vue.filter('phone', function(value: any) {
  if (value) {
    let phoneNumbers = [...value];
    phoneNumbers.splice(0, 0, '(');
    phoneNumbers.splice(4, 0, ')');
    phoneNumbers.splice(5, 0, ' ');
    phoneNumbers.splice(9, 0, '-');
    return phoneNumbers.join('');
  }
});
