import { BaseClass } from '../base/base.service';
import { ReportsType } from '@/pages/Reports/models/reports.model';
import { StatistcsPeriod } from '@/pages/Reports/models/statistics.model';

export class StatisticsProvider extends BaseClass {
  constructor() {
    super('statistics/');
  }

  async getStatistic(period: string, filter: string) {
    const res = await this.get(`${StatistcsPeriod[period]}`, { filter });
    return res.data.data;
  }

  async getExcel(chunk: string, filter: string) {
    const res = await this.get(`${chunk}/excel`, { filter });
    return res.data;
  }
}

export const StatisticsService = new StatisticsProvider();
