import { WoBaseModel } from './wo-base-model';

export class BareChassis extends WoBaseModel {
  billToName = '';
  referenceNumber = '';
  deliveryNumber = '';
  chassisNumber = '';
  pulloutChassisNumber = '';
  chassisPool = '';
  dropLive = 'DROP';
  sendTo = '';
  hot = false;
  remarks = '';
  wms = false;
  tare = '';
}
