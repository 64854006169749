import { BareChassis } from './bare-chassis';
import { BareChassisValidation } from './bare-chassis-validation';
import { CyToCy } from './cy-to-cy';
import { CyToCyValidation } from './cy-to-cy-validation';
import { ExportRegular } from './export-regular';
import { ExportRegularValidation } from './export-regular-validation';
import { ExportShuttle } from './export-shuttle';
import { ExportShuttleValidation } from './export-shuttle-validation';
import { ImportRegular } from './import-regular';
import { ImportRegularValidation } from './import-regular-validation';
import { ImportShuttle } from './import-shuttle';
import { ImportShuttleValidation } from './import-shuttle-validation';
import { VanRegular } from './van-regular';
import { VanRegularValidation } from './van-regular-validation';

export enum WoType {
  ImportShuttle = 'IMP-SHUTTLE',
  ImportRegular = 'IMP-REG',
  ExportRegular = 'EXP-REG',
  ExportShuttle = 'EXP-SHUTTLE',
  VanRegular = 'VAN',
  CyToCy = 'CY-TO-CY',
  BareChassis = 'BARE-CHASSIS',

  // TODO update after API reports wil be done
  IMP = 'IMP',
  EXP = 'EXP',
  CTY = 'CTY',
  CHS = 'CHS'
}

export const WoAction = {
  [WoType.ImportShuttle]: '/imports',
  [WoType.ImportRegular]: '/imports',
  [WoType.ExportRegular]: '/exports',
  [WoType.ExportShuttle]: '/exports',
  [WoType.VanRegular]: '/vans',
  [WoType.CyToCy]: '/cities',
  [WoType.BareChassis]: '/chassis',

  // TODO update after API reports wil be done

  [WoType.IMP]: '/imports',
  [WoType.EXP]: '/exports',
  [WoType.CTY]: '/cities',
  [WoType.CHS]: '/chassis',
};

export const woModels = {
  [WoType.ImportShuttle]: ImportShuttle,
  [WoType.ImportRegular]: ImportRegular,
  [WoType.ExportRegular]: ExportRegular,
  [WoType.ExportShuttle]: ExportShuttle,
  [WoType.VanRegular]: VanRegular,
  [WoType.CyToCy]: CyToCy,
  [WoType.BareChassis]: BareChassis
};

export const woModelsValidation = {
  [WoType.ImportShuttle]: ImportShuttleValidation,
  [WoType.ImportRegular]: ImportRegularValidation,
  [WoType.ExportRegular]: ExportRegularValidation,
  [WoType.ExportShuttle]: ExportShuttleValidation,
  [WoType.VanRegular]: VanRegularValidation,
  [WoType.CyToCy]: CyToCyValidation,
  [WoType.BareChassis]: BareChassisValidation
};
