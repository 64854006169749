import {
  required,
  maxLength,
  numeric,
  requiredIf,
  minValue
} from 'vuelidate/lib/validators';
import { chass, email, count } from './wo-validation';
import { ExportShuttle } from './export-shuttle';

export const ExportShuttleValidation: any = {
  billTo: { required },
  bookingNumber: { required, maxLength: maxLength(20) },
  houseBillOfLading: { maxLength: maxLength(20) },
  referenceNumber: { required, maxLength: maxLength(30) },
  poNumber: { maxLength: maxLength(50) }, // PO ?
  deliveryNumber: { maxLength: maxLength(20) },
  containerNumber: { count }, // missed,
  containerSize: { required },
  containerType: { required },
  shippingLine: {},
  chassisNumber: { chass },
  pulloutChassisNumber: { chass },
  freeDays: {
    required,
    maxLength: maxLength(2),
    minValue: minValue(1),
    numeric
  },
  freeDaysType: { required },
  seal: { maxLength: maxLength(20) },
  commodity: { maxLength: maxLength(50) },
  weight: { maxLength: maxLength(10), numeric },
  quantity: { maxLength: maxLength(5), numeric },
  unit: {},
  sendTo: { maxLength: maxLength(256), email },
  remarks: { maxLength: maxLength(30) },
  pulloutLocation: {},
  deliveryLocation: { required },
  returnLocation: {},
  dropLive: { required },
  do: { required },
  bondEntry: {
    required: requiredIf((form: ExportShuttle) => !!form.cf),
    maxLength: maxLength(10)
  },
  cf: {
    required: requiredIf((form: ExportShuttle) => !!form.bondEntry)
  },
  pulloutSchedule: {},
  deliverySchedule: {},
  returnSchedule: {},
  chassisPool: { required: requiredIf(form => !!form.chassisNumber) },
  cutOff: {}
};
