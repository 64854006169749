export const EarlyWarningDirective = {
  bind
};

function bind(element: HTMLElement, bindings) {
  if (!bindings.value.enable) {
    return;
  }

  const field = bindings.value.fields?.find(i => i[bindings.value.name]);

  if (field) {
    element.classList.add(`early-warning-level-${field[bindings.value.name]}`);
  }
}
