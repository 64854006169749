import {
  required,
  maxLength,
  numeric,
  requiredIf
} from 'vuelidate/lib/validators';
import { chass, email, count } from './wo-validation';
import { ImportShuttle } from './import-shuttle';

export const ImportShuttleValidation: any = {
  billTo: { required },
  masterBillOfLading: { required, maxLength: maxLength(20) },
  houseBillOfLading: { maxLength: maxLength(20) },
  referenceNumber: { required, maxLength: maxLength(30) },
  poNumber: { maxLength: maxLength(50) }, // PO ?
  deliveryNumber: { maxLength: maxLength(20) },
  containerNumber: { required, count }, // missed,
  containerSize: { required },
  containerType: { required },
  shippingLine: { required },
  chassisNumber: { chass },
  pulloutChassisNumber: { chass },
  seal: { required, maxLength: maxLength(20) },
  commodity: { maxLength: maxLength(50) },
  weight: { required, maxLength: maxLength(10), numeric },
  quantity: { required, maxLength: maxLength(5), numeric },
  unit: { required },
  sendTo: { maxLength: maxLength(256), email },
  remarks: { maxLength: maxLength(30) },
  pulloutLocation: { required },
  deliveryLocation: { required },
  returnLocation: {},
  dropLive: { required },
  do: { required },
  bondEntry: {
    required: requiredIf((form: ImportShuttle) => !!form.cf),
    maxLength: maxLength(10)
  },
  cf: {
    required: requiredIf((form: ImportShuttle) => !!form.bondEntry)
  },
  pulloutSchedule: {},
  deliverySchedule: {},
  returnSchedule: {},
  chassisPool: { required: requiredIf(form => !!form.chassisNumber) }
};
