import { EarlyWarningService } from './../../../shared/services/early-warning/early-warning.service';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { ReportsData, ExcelData } from './reports.model';
import { ReportsType } from '@/pages/Reports/models/reports.model';
import { ReportsService } from '@/shared/services/reports/reports.service';

import {
  FilterInput,
  TransformFiltersToJson,
  TransformFilterToPlain
} from '@/shared/services/filter/filter.service';

import { ToastHelper } from '@/utils/toast.util';

@Module({
  namespaced: true,
  name: 'reports'
})
export default class ReportsModule extends VuexModule {
  loading = false;
  modalLoading = false;
  reports: ReportsData[] | any = [];
  reportsCities: [];
  ordersList = [];
  reportType: ReportsType = ReportsType.PULLOUT;
  filters: FilterInput<any>[] = [];
  statistics = [];
  earlyWarning = [];
  statisticsPeriod = 'weekly';
  excelData: ExcelData | any = {};
  allowSendingEmails = false;

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_MODAL_LOADING_STATUS(loading: boolean) {
    this.modalLoading = loading;
  }

  @Mutation
  public SET_FILTERS(filters: FilterInput<any>[]) {
    this.filters = filters;
  }

  @Mutation
  public SET_REPORT_TYPE(payload: ReportsType) {
    this.reportType = payload;
  }

  @Mutation
  public SET_REPORTS(payload: ReportsData[]) {
    this.reports = [...payload];
  }

  @Mutation
  public SET_EARLY_WARNING(payload: any) {
    this.earlyWarning = [...payload];
  }

  @Mutation
  public SET_ORDERS_LIST(payload) {
    this.ordersList = [...payload];
  }

  @Mutation
  public SET_STATISTICS(payload) {
    console.log(payload);
    this.statistics = [...payload];
  }

  @Mutation
  public UPDATE_STATISTICS_PERIOD(payload) {
    this.statisticsPeriod = payload;
  }

  @Mutation
  public SET_EXCEL_DATA(payload) {
    this.excelData = { ...payload };
  }

  @Mutation
  public SET_EMAILS_PERMIT(payload) {
    this.allowSendingEmails = payload;
  }

  @Mutation
  public SET_REPORTS_CITIES(payload) {
    this.reportsCities = payload;
  }

  @Action
  async load(payload: { reportType: ReportsType; filters?: string }) {
    const { reportType, filters } = { ...payload };

    this.context.commit('SET_REPORT_TYPE', reportType);
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await ReportsService.requestSearch(reportType, filters);
      this.context.commit('SET_REPORTS', res);
    } catch (err) {
      console.log('Reports Error', err);
      ToastHelper.show('Reports', err.response.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async search() {
    this.context.commit('SET_LOADING_STATUS', true);
    try {
      const res = await ReportsService.requestSearch(
        this.reportType,
        TransformFiltersToJson(TransformFilterToPlain([...this.filters])),
        this.statisticsPeriod
      );
      this.context.commit('SET_REPORTS', res);
    } catch (err) {
      console.log('Reports Error', err);
      ToastHelper.show('Reports', err.response.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  setReportType(reportType: ReportsType) {
    this.context.commit('SET_REPORT_TYPE', reportType);
  }

  @Action
  async loadOrders(payload: { reportType: ReportsType; filters?: string }) {
    const { reportType, filters } = { ...payload };

    this.context.commit('SET_MODAL_LOADING_STATUS', true);
    const orders = await ReportsService.getOrders(reportType, filters);
    this.context.commit('SET_ORDERS_LIST', orders);
    this.context.commit('SET_MODAL_LOADING_STATUS', false);
  }

  @Action
  async loadEarlyWarning() {
    this.context.commit('SET_REPORT_TYPE', ReportsType['EARLY-WARNING']);
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await EarlyWarningService.search(
        TransformFiltersToJson(TransformFilterToPlain([...this.filters]))
      );

      this.context.commit(
        'SET_EARLY_WARNING',
        res?.data?.data.map(i => {
          return {
            ...i,
            status: i.status === 'SOLVED' ? 'SOLVED' : i.status === 'COMPLETE'
          };
        })
      );
    } catch (err) {
      console.log('Reports Error', err);
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async loadStatistics() {
    this.context.commit('SET_REPORT_TYPE', ReportsType.STATISTICS);
    this.context.commit('SET_LOADING_STATUS', true);
    this.context.commit('SET_STATISTICS', []);

    try {
      const res = await ReportsService.getSchedulesStatistics(
        this.statisticsPeriod,
        TransformFiltersToJson(TransformFilterToPlain([...this.filters]))
      );
      this.context.commit('SET_STATISTICS', res);

      // let allReportsCities = [];
      // this.statistics.forEach(statistic => {
      //   let reportCity = [];
      //   for (const element in statistic) {
      //     if (typeof statistic[element] === 'object') {
      //       if (statistic.hasOwnProperty(element)) {
      //         for (const city in statistic[element]) {
      //           if (reportCity.indexOf(city) == -1) {
      //             reportCity = [...reportCity, city];
      //           }
      //         }
      //       }
      //     }
      //   }
      //   allReportsCities = [...allReportsCities, reportCity];
      // });
      // this.context.commit('SET_REPORTS_CITIES', allReportsCities);
    } catch (err) {
      console.log('Reports Error', err);
    }

    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async updateStatisticsPeriod(payload: string) {
    this.context.commit('UPDATE_STATISTICS_PERIOD', payload);
  }

  @Action
  async excel(reportType: ReportsType) {
    this.context.commit('SET_LOADING_STATUS', true);
    try {
      const res = await ReportsService.getReportsExcelData(
        reportType,
        TransformFiltersToJson(TransformFilterToPlain([...this.filters])),
        this.statisticsPeriod
      );
      this.context.commit('SET_EXCEL_DATA', res);
    } catch (e) {
      console.log('Reports excel Error', e);
      ToastHelper.show('Reports excel', e.response.data?.error);
    }
    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async setFilters(filters) {
    this.context.commit('SET_FILTERS', filters);
  }

  @Action
  setReports(reports) {
    this.context.commit('SET_REPORTS', reports);
  }

  @Action
  permitSendingEmails(trigger: boolean) {
    this.context.commit('SET_EMAILS_PERMIT', trigger);
  }
}
