import { BaseClass } from '../base/base.service';
import { DispatchType } from '@/pages/Dispatch/models/dispatch.model';

export class DispatchProvider extends BaseClass {
  constructor() {
    super('dispatches/');
  }

  async searchRequest(
    dispatchType: DispatchType,
    category: string,
    filters?: string
  ) {
    switch (dispatchType) {
      case DispatchType.IMP: {
        return await this.getList('imports', category, filters);
      }
      case DispatchType.EXP: {
        return await this.getList('exports', category, filters);
      }
      case DispatchType.VAN: {
        return await this.getList('vans', category, filters);
      }
      case DispatchType.BOOK: {
        return await this.getList('demurrage', category, filters);
      }
    }
  }

  async saveRequest(
    dispatchType: DispatchType,
    category: string,
    orderNumber: string,
    data: object
  ) {
    switch (dispatchType) {
      case DispatchType.IMP: {
        return await this.saveItem('imports', category, orderNumber, data);
      }
      case DispatchType.EXP: {
        return await this.saveItem('exports', category, orderNumber, data);
      }
      case DispatchType.VAN: {
        return await this.saveItem('vans', category, orderNumber, data);
      }
      case DispatchType.BOOK: {
        return await this.saveItem('demurrage', category, orderNumber, data);
      }
    }
  }

  async addRequest(dispatchType: DispatchType, category: string, data: object) {
    switch (dispatchType) {
      case DispatchType.IMP: {
        return await this.addItem('imports', category, data);
      }
      case DispatchType.EXP: {
        return await this.addItem('exports', category, data);
      }
      case DispatchType.VAN: {
        return await this.addItem('vans', category, data);
      }
      case DispatchType.BOOK: {
        const demurrage = await this.addItem('demurrage', category, data);
        return demurrage;
      }
    }
  }

  async getList(type: string, category: string, filter?: string) {
    let res;

    if (filter) {
      if (category !== 'booking') {
        // limit: -1 means get ALL
        res = await this.get(`${type}/${category}`, { filter, limit: -1 });
      } else {
        // limit: -1 means get ALL
        res = await this.get(`bookings`, { filter, limit: -1 });
      }

      return res.data;
    } else {
      return [];
    }
  }

  async saveItem(type: string, category: string, ON: string, data: object) {
    let res;
    if (category === 'booking') {
      res = await this.put(`bookings/${ON}`, data);
    } else {
      res = await this.put(`${type}/${category}/${ON}`, data);
    }
    return res.data;
  }

  async addItem(type: string, category: string, data: object) {
    let res;
    if (category === 'booking') {
      res = await this.post(`bookings`, data);
    } else {
      res = await this.post(`${type}/${category}`, data);
    }
    return res.data;
  }

  async getExcel(type: string, category, filter?: string) {
    const res = await this.get(`${type}/${category}/excel`, { filter });
    return res.data;
  }

  async getDispatchesExcelData(
    dispatchType: DispatchType,
    category: string,
    filters?: string
  ) {
    switch (dispatchType) {
      case DispatchType.IMP: {
        return await this.getExcel('imports', category, filters);
      }
      case DispatchType.EXP: {
        return await this.getExcel('exports', category, filters);
      }
      case DispatchType.VAN: {
        return await this.getExcel('vans', category, filters);
      }
    }
  }

  async deleteFileById(id: string, category: string) {
    await this.delete(`bookings/${id}/files/${category}`);
  }

  async getFileLink(id: string, category: string) {
    const res = await this.get(`bookings/${id}/files/${category}`);
    return res.data;
  }

  getSelect(select) {
    return this.convertDataToSelectValues(select, 'id', 'code');
  }

  //===========booking===========

  async getBookingById(id: string) {
    const res = await this.get(`/bookings/${id}`);
    return res;
  }

  async completeBookings(data: any) {
    await this.put(`bookings/complete`, data);
  }

  async inCompleteBookings(data: any) {
    await this.put(`bookings/incomplete`, data);
  }

  async deleteBooking(id: string) {
    await this.delete(`/bookings/${id}`);
  }

  //===========booking===========
}

export const DispatchService = new DispatchProvider();
