import { BaseClass } from '../base/base.service';

export class UserManagementProvider extends BaseClass {
  constructor() {
    super('users');
  }

  limit = 10000;

  async getUsers(filter, page = this.page, limit = this.limit) {
    const res = await this.get('/', {
      filter,
      limit,
      page
    });

    return res.data;
  }

  async getUserById(id: string) {
    return await this.get(`/${id}`);
  }

  async createUser(data) {
    const res = await this.post('/', data);
    return res.data;
  }

  async updateUser(id: string, data) {
    return await this.put(`/${id}`, data);
  }

  async deleteUserById(id: string) {
    return await this.delete(`/${id}`);
  }

  async changeUserActive(id: string, isActive: boolean) {
    const res = await this.patch(`/${id}?active=${isActive}`, null);
    return res.data;
  }

  async passwordReset(uuid: string) {
    const res = await this.post(`/${uuid}/password/reset`, null);
    return res.data;
  }
}
export const UserManagementService = new UserManagementProvider();
