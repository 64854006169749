

















































import { WoType } from '@/shared/components/wo/models';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class WoModalSearch extends Vue {
  @Prop() woType: string;
  woCategoryType = WoType;

  searchData(order) {
    if (!order) {
      return;
    }

    this.$emit('select', order);
  }

  search(field: string, search: string) {
    return WorkOrderService.searchOrderIdentifiers(
      this.woType,
      field,
      search.toUpperCase()
    );
  }
}
