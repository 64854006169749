import { TransformFiltersToJson } from '../filter/filter.service';
import { SelectModel } from './../../models/generic.model';
import { ApiService } from './api.service';

export class BaseClass extends ApiService {
  limit = 5;
  page = 1;

  transformFiltersToJson = TransformFiltersToJson;

  constructor(baseURL: string) {
    super(baseURL);
  }

  convertDataToSelectValues(
    data: any,
    valueKey: string,
    textKey: string
  ): SelectModel[] {
    return data.map((item: any) => ({
      value: item[valueKey],
      text: item[textKey]
    }));
  }

  sortAfabeticalyByKey(data: any[], key: string) {
    return data.sort((a, b) => a[key].localeCompare(b[key]));
  }
}
