

















import { Component } from 'vue-property-decorator';
import { WoModalBaseClass } from './WoModalBase.class';

@Component({})
export default class LocationModal extends WoModalBaseClass {
  constructor() {
    super();
  }
}
