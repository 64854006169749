







































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';

@Component({})
export default class AutoSuggest extends Vue {
  @Prop({ default: null }) placeholder: string;
  @Prop({ default: 20 }) maxCount: string;
  @Prop({ default: null }) data: any;
  @Prop({ default: null }) size: string;
  @Prop({ default: null }) modelKey: string;
  @Prop({ default: null }) state: any;
  @Prop({ default: null }) fetch: Function;
  @Prop({ default: false }) transparent: boolean;
  @Prop({ default: true }) checkEmptyString: boolean;
  @Prop({ default: null }) cacheKey: string;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '' }) initialQuery: string;
  @Prop({ default: null }) mask: any;
  @Prop({ default: null }) serializerProps: Function;

  @Watch('initialQuery')
  onInitialChange() {
    this.query = this.initialQuery;
  }

  @Model()
  model: string;

  @Prop({ default: value => value }) serializer: Function;

  dataList: any = [];
  query: string = null;

  classList = `auto-suggest-wrapper`;

  constructor() {
    super();

    this.query = this.initialQuery;
  }

  /**
   * Validate input parameters
   * At least fetch function or data is required.
   *
   */
  created() {
    if (!this.fetch && !this.data) {
      throw new Error(
        'AutoSuggest: fetch function or data list propery is required'
      );
    }

    if (this.transparent) {
      this.classList = `${this.classList} td-input`;
    }

    if (this.required) {
      this.classList = `${this.classList} required`;
    }

    if (this.data) {
      this.dataList = this.data;
    }

    if (this.cacheKey) {
      this.getFromCache();
    }
  }

  mounted() {
    if (this.model) {
      (this.$refs.typeahead as any).inputValue = this.model;
    }
  }

  /**
   * Update v-model with selected value from the list
   * If modelKey provided try to get it form the value object
   *
   */
  onSelect(value: Record<string, any> | string) {
    if (this.modelKey) {
      if (!value[this.modelKey]) {
        throw new Error(
          `AutoSuggest: can't find key ${this.modelKey} in ${value}`
        );
      }
      this.$emit('input', value[this.modelKey]);
    } else {
      this.$emit('input', value);
    }

    this.$emit('select', value);

    if (this.cacheKey) {
      this.addToCache(value);
    }
  }

  onInput(value: string) {
    if (this.checkEmptyString && value === '') {
      this.$emit('select', null);
      this.$emit('input', null);
    } else {
      this.$emit('input', value);
    }
  }

  /**
   * Watch query model change and get new data with fetch method.
   *
   * Use API only if no static data provided
   *
   */
  @Watch('query')
  @Debounce(300)
  async fetchData(query) {
    if (!query.length || this.data) {
      this.$emit('input', query);
      return;
    }
    this.dataList = await this.fetch(this.query);
  }

  @Watch('model')
  onPropertyChanged(value: string) {
    (this.$refs.typeahead as any).inputValue = value;
  }

  addToCache(data: any) {
    // Skipp cached items
    if (data.cached) {
      return;
    }

    try {
      let list = JSON.parse(
        localStorage.getItem(`tms.autoSuggest.${this.cacheKey}`)
      );

      if (!list) {
        list = [];
      }

      list?.push({ ...data, cached: true });

      localStorage.setItem(
        `tms.autoSuggest.${this.cacheKey}`,
        JSON.stringify(list)
      );
    } catch (e) {
      data = null;
    }
  }

  getFromCache() {
    let data: any;
    try {
      data = JSON.parse(
        localStorage.getItem(`tms.autoSuggest.${this.cacheKey}`)
      );
    } catch (e) {
      data = null;
    }

    if (data) {
      this.dataList = data;
    }
  }
}
