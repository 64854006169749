import { AdminConfService } from './../admin-conf/admin-conf.service';
import _ from 'lodash';

import { BaseClass } from '../base/base.service';
import { WorkOrder } from './../../../store/modules/workOrder/work-order.model';
import {
  combineDateAndTime,
  convertAPIToViewFullFormat,
  convertDateToAPIFormat
} from './../../../utils/date.util';
import {
  arrayConvertStringsToInteger,
  convertStringsToInteger,
  removeEmptyFormProperties
} from './../../../utils/form.util';
import { ToastHelper } from './../../../utils/toast.util';
import {
  deepClone,
  removeFields,
  removeFieldsFromArray
} from './../../../utils/utils';
import { WoAction } from './../../components/wo/models';
import {
  chassisPool,
  containerSize,
  containerType,
  shippingLine
} from './../../mocks/wo';
import { FilterConditions } from './../filter/filter.service';

class WorkOrderProvider extends BaseClass {
  scheduleFieldToRemoveBeforeUpdate = [
    'createdBy',
    'updatedBy',
    'createdDate',
    'updatedDate',
    'memos',
    'scheduleInTime',
    'actualInTime',
    'actualOutTime',
    'assign',
    'status'
  ];

  fieldToRemoveBeforeUpdate = [
    'date',
    'acctId',
    'clientId',
    'createdBy',
    'orderNumber',
    'orderPrefix',
    'pulloutAddress',
    'deliveryAddress',
    'returnAddress',
    'accountPayables',
    'accountReceivables',
    'files',
    'do',
    'cf',
    'avaDate',
    'bookingNumberMemos',
    'status',
    'completed'
  ];

  constructor() {
    super('orders');
  }

  async saveMemo(type: string, orderNumber: string, data) {
    const res = await this.post(`${WoAction[type]}/${orderNumber}/memos`, data);
    return res.data;
  }

  async udpateMemo(type: string, orderNumber: string, data) {
    const res = await this.put(`${WoAction[type]}/${orderNumber}/memos`, data);
    return res.data;
  }

  async deleteOrder(type: string, orderNumber: string) {
    const res = await this.delete(`${WoAction[type]}/${orderNumber}`)
      .then(() => {
        ToastHelper.show(type, `${orderNumber} is deleted.`, 8000, 'success');
        return 200;
      })
      .catch(error => {
        ToastHelper.show(
          type,
          `Order ${orderNumber} can’t be deleted because of Billing Info is not empty.`,
          8000,
          'danger'
        );
        return error;
      });
    return res;
  }

  async completeOrder(
    type: string,
    orderNumber: string,
    reportType = 'wo',
    complete = true
  ) {
    const completeType = complete ? 'uncomplete' : 'complete';
    console.log(type);
    const res = await this.put(
      `${
        reportType === 'dispatch' ? `${type}s` : WoAction[type]
      }/${orderNumber}/${completeType}`,
      ''
    )
      .then(response => {
        ToastHelper.show(
          type,
          `${orderNumber} is ${completeType} success.`,
          8000,
          'success'
        );
        return response;
      })
      .catch(e => {
        ToastHelper.show(type, `${orderNumber} ${e}`, 8000, 'danger');
      });
    return res;
  }

  async importOrder(type: string, orderNumber: string, reportType = 'wo') {
    const res = await this.put(
      `${
        reportType === 'dispatch' ? type : WoAction[type]
      }/${orderNumber}/import`,
      ''
    )
      .then(response => {
        ToastHelper.show(
          type,
          `${orderNumber} is import success.`,
          8000,
          'success'
        );
        return response.data;
      })
      .catch(e => {
        ToastHelper.show(
          type,
          `${orderNumber} ${e?.response?.data?.error || e.message}`,
          8000,
          'danger'
        );
      });
    return res;
  }

  async exportOrder(type: string, orderNumber: string, reportType = 'wo') {
    const res = await this.put(
      `${
        reportType === 'dispatch' ? type : WoAction[type]
      }/${orderNumber}/export`,
      null
    )
      .then(response => {
        ToastHelper.show(
          type,
          `${orderNumber} is export success.`,
          8000,
          'success'
        );
        return response.data;
      })
      .catch(e => {
        ToastHelper.show(
          type,
          `${orderNumber} ${e?.response?.data?.error || e.message}`,
          8000,
          'danger'
        );
        throw e;
      });
    return res;
  }

  async search(type: string, field: string, search: string) {
    const res = await this.get(WoAction[type], {
      filter: this.transformFiltersToJson([
        {
          search,
          field,
          condition: FilterConditions.BeginsWith
        },
        {
          field: 'date',
          search: '20210215',
          condition: FilterConditions.LessThenOrEqual
        },
        {
          field: 'date',
          search: '20210215',
          condition: FilterConditions.GreaterOrEqual
        }
      ])
    });

    return res.data.data.map(i => ({ ...i, code: `${i.code}` }));
  }

  async getWorkOrder(type: string, id: string) {
    const res = await this.get(`${WoAction[type]}/${id}`);

    // Build jobPayments object to be able to change driver assign
    if (res.data.schedules) {
      res.data.jobPayments = res.data.schedules
        .filter(schedule => schedule.jobUuid)
        .map(schedule => ({
          driverId: schedule.driverId,
          jobUuid: schedule.jobUuid,
          payments: [],
          shouldSkip: true
        }));

      res.data.jobPayments = _.uniqBy(res.data.jobPayments, 'jobUuid');
    }

    return res.data;
  }

  async syncAPAndAR(type: string, id: string) {
    return this.post(`${WoAction[type]}/${id}/sync-ap-ar`, {});
  }

  async getWorkOrderAPandAR(type: string, id: string) {
    const req = [
      this.get(`${WoAction[type]}/${id}/account-receivables`).then(
        ({ data }) => data
      ),
      this.get(`${WoAction[type]}/${id}/account-payables`).then(
        ({ data }) => data
      )
    ];

    return await Promise.all(req);
  }

  async update(type: string, id: string, wo) {
    const woData = deepClone(wo);
    if (woData.schedules) {
      const dateKeys = ['scheduleIn', 'actualIn', 'actualOut'];

      dateKeys.forEach(key => {
        woData.schedules.forEach(row => {
          if (row[key] === 'Invalid date') {
            row[key] = null;
          }
          if (row[key + 'Time'] === 'Invalid date') {
            row[key + 'Time'] = null;
          }

          if (row[key] && row[key + 'Time']) {
            row.scheduleIn = combineDateAndTime(
              row.scheduleIn,
              row.scheduleInTime
            );
            row.actualIn = combineDateAndTime(row.actualIn, row.actualInTime);
            row.actualOut = combineDateAndTime(
              row.actualOut,
              row.actualOutTime
            );
          }
        });
      });
    }

    const data = removeFields(woData, this.fieldToRemoveBeforeUpdate, type);
    data.schedules = data.schedules.map(schedule =>
      removeFields(schedule, this.scheduleFieldToRemoveBeforeUpdate, type)
    );

    removeEmptyFormProperties(data);

    convertStringsToInteger(data, ['freeDays', 'weight', 'quantity', 'tare']);

    // BE validation fix for empty string
    if (data.returnSchedule === '') {
      data.returnSchedule = null;
    }

    return await this.put(`${WoAction[type]}/${id}`, data);
  }

  async updateAccountPayables(
    type: string,
    orderNumber: string,
    accountPayables,
    woLabel: string,
    successMsg: string
  ) {
    const data = removeFieldsFromArray(accountPayables, [
      'acctId',
      'accType',
      'ck',
      'status',
      'invoiceAmount',
      'paidAmount',
      'disabled'
    ]);
    data.forEach(i => removeEmptyFormProperties(i));
    data.forEach(row => (row.amount = Number(row.amount)));
    return this.put(`${WoAction[type]}/${orderNumber}/account-payables`, data);
  }

  async updateAccountReceivables(
    type: string,
    orderNumber: string,
    accountReceivables,
    woLabel: string,
    successMsg: string
  ) {
    const data = removeFieldsFromArray(accountReceivables, [
      'acctId',
      'accType',
      'ck',
      'status',
      'invoiceAmount',
      'paidAmount'
    ]);
    data.forEach(i => removeEmptyFormProperties(i));
    data.forEach(row => (row.rate = Number(row.rate)));
    arrayConvertStringsToInteger(data, ['quantity']);
    return this.put(
      `${WoAction[type]}/${orderNumber}/account-receivables`,
      data
    );
  }

  async getSelectValues() {
    return await AdminConfService.getSelectData();

    // return {
    //   containerType: this.convertDataToSelectValues(
    //     containerType,
    //     'id',
    //     'code'
    //   ),
    //   containerSize: this.convertDataToSelectValues(
    //     containerSize,
    //     'id',
    //     'code'
    //   ),
    //   chassisPool: chassisPool,
    //   shippingLine: this.convertDataToSelectValues(shippingLine, 'id', 'code')
    // };

  }

  updatePullOutSchedule(data, schedules) {
    this.updateSchedule(data, schedules[0]);
  }

  updateDeliverySchedule(
    data,
    previousLocation,
    schedules,
    returnExist: boolean
  ) {
    const skip = [0];
    if (returnExist) {
      skip.push(schedules[schedules.length - 1]);
    }
    schedules.forEach((schedule, index) => {
      if (
        skip.indexOf(index) === -1 &&
        schedule.location === previousLocation
      ) {
        this.updateSchedule(data, schedule);
      }
    });
  }

  updateReturnSchedule(data, schedules, createNew = false) {
    if (createNew) {
      schedules.push({});
    }
    this.updateSchedule(data, schedules[schedules.length - 1]);
  }

  private updateSchedule(data, schedule) {
    schedule.city = data.city;
    schedule.state = data.state;
    schedule.location = data.id;
  }

  convertWOToViewFormat(wos: WorkOrder[] | WorkOrder) {
    if (Array.isArray(wos)) {
      return wos.map((wo: WorkOrder) => this.convertWO(wo));
    } else {
      return this.convertWO(wos);
    }
  }

  private convertWO(wo: WorkOrder) {
    const dateKeys = [
      'eta',
      'lastFreeDay',
      'avaDate',
      'returnFreeDay',
      'erd',
      'streetTurn',
      'cutOff',
      'date',
      'pulloutActualIn',
      'pulloutActualOut',
      'pulloutSchedule',
      'deliveryActualIn',
      'deliveryActualOut',
      'deliverySchedule',
      'pickupActualIn',
      'pickupActualOut',
      'pickupSchedule',
      'returnActualIn',
      'returnActualOut',
      'returnSchedule',
      'loadConfirmation',
      'emptyConfirmation'
    ];

    dateKeys.forEach(key => {
      if (wo[key]) {
        wo[key] = convertAPIToViewFullFormat(wo[key]);
      }
    });

    return wo;
  }

  async searchOrderIdentifiers(type: string, field: string, search: string) {
    const res = await this.get(`${WoAction[type]}/identifiers`, {
      filter: this.transformFiltersToJson([
        {
          search,
          field,
          condition: FilterConditions.BeginsWith
        }
      ])
    });

    return res.data.data;
  }

  async updateResponsibilityReason(orderNumber, fields) {
    const res = await this.patch(`/imports/${orderNumber}`, { ...fields })
      .then(response => {
        ToastHelper.show(
          'Responsibility and Reason',
          `Successful saved`,
          8000,
          'success'
        );
        return response;
      })
      .catch(e => {
        ToastHelper.show('QuickBooks', e.message, 8000, 'danger');
      });
    return res;
  }

  async sendQB(
    type: string,
    orderNumber: string,
    billingType,
    rowIds: Array<string>
  ) {
    const res = await this.post(
      `${WoAction[type]}/${orderNumber}/${billingType}/qb`,
      rowIds
    )
      .then(response => {
        ToastHelper.show(
          'QuickBooks',
          `Successful send to QB`,
          8000,
          'success'
        );
        return response;
      })
      .catch(e => {
        ToastHelper.show(
          'QuickBooks',
          e?.response?.data?.error || e.message,
          8000,
          'danger'
        );
        throw e;
      });
    return res;
  }
}

export const WorkOrderService = new WorkOrderProvider();
