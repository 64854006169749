import { UserModule } from './../../store';
import { NavigationGuardNext, Route } from 'vue-router';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (!UserModule.isAuthorized) {
    //   next({
    //     // path: '/auth'
    //   });
    // }
  }
  next();
};
