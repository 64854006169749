import {
  required,
  maxLength,
  numeric,
  requiredIf,
  minValue
} from 'vuelidate/lib/validators';
import { chass, email, count } from './wo-validation';

export const CyToCyValidation: any = {
  billTo: { required },
  masterBillOfLading: { required, maxLength: maxLength(20) },
  houseBillOfLading: { maxLength: maxLength(20) },
  referenceNumber: { required, maxLength: maxLength(30) },
  poNumber: { maxLength: maxLength(50) }, // PO ?
  deliveryNumber: { maxLength: maxLength(20) },
  containerNumber: { required, count }, // missed,
  containerSize: { required },
  containerType: { required },
  shippingLine: { required },
  chassisNumber: { chass },
  pulloutChassisNumber: { chass },
  freeDays: {
    required,
    maxLength: maxLength(2),
    minValue: minValue(1),
    numeric
  },
  freeDaysType: { required },
  seal: { required, maxLength: maxLength(20) },
  commodity: { maxLength: maxLength(50) },
  weight: { required, maxLength: maxLength(10), numeric },
  quantity: { required, maxLength: maxLength(5), numeric },
  unit: { required },
  sendTo: { maxLength: maxLength(256), email },
  remarks: { maxLength: maxLength(30) },
  pulloutLocation: { required },
  deliveryLocation: { required },
  returnLocation: {
    required: requiredIf(form => !!form.returnSchedule)
  },
  dropLive: { required },
  do: { required },
  pulloutSchedule: {},
  deliverySchedule: {},
  returnSchedule: {},
  chassisPool: { required: requiredIf(form => !!form.chassisNumber) }
};
